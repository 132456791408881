export default [
    {label:'Ημίαιμο', value:1, en:'Half-blood', alt:'ημιαιμο'},
    {label:'Αφενπίνσερ', value:2, en:'Affenpinscher', alt:'αφενπινσερ'},
    {label:'Αφγανικό κυνηγόσκυλο', value:3, en:'Afghan Hound', alt:'αφγανικο κυνηγοσκυλο'},
    {label:'Έρντεϊλ Τεριέ', value:4, en:'Airedale Terrier', alt:'ερντειλ τεριε'},
    {label:'Μαλαμούτ Αλάσκας', value:5, en:'Alaskan Malamute', alt:'μαλαμουτ αλασκας'},
    {label:'Ποιμενικός Ανατολίας', value:6, en:'Anatolian Shepherd', alt:'ποιμενικος ανατολιας'},
    {label:'Αυστραλιανός Σκύλος Βοοειδών', value:7, en:'Australian Cattle Dog', alt:'αυστραλιανος σκυλος βοοειδων'},
    {label:'Ποιμενικός Αυστραλίας', value:8, en:'Australian Shepherd Dog', alt:'ποιμενικος αυστραλιας'},
    {label:'Αυστραλιανό Σίλκι Τεριέ', value:9, en:'Australian Silky Terrier', alt:'αυστραλιανο σιλκι τεριε'},
    {label:'Αυστραλιανό Τεριέ', value:10, en:'Australian Terrier', alt:'αυστραλιανο τεριε'},
    {label:'Μπασέντζι', value:11, en:'Basenji', alt:'μπασεντζι'},
    {label:'Μπασέ Μπλε της Γασκώνης', value:12, en:'Basset Bleu De Gascogne', alt:'μπασε μπλε της γασκωνης'},
    {label:'Μπασέ Φοβ της Βρετάνης', value:13, en:'Basset Fauve De Bretagne', alt:'μπασε φοβ της βρετανης'},
    {label:'Μπασέ Γκριφόν Βανδέας (Μεγάλο)', value:14, en:'Basset Griffon Vendeen (Grand)', alt:'μπασε γκριφον βανδεας (μεγαλο)'},
    {label:'Μπασέ Γκριφόν Βανδέας (Μικρό)', value:15, en:'Basset Griffon Vendeen (Petit)', alt:'μπασε γκριφον βανδεας (μικρο)'},
    {label:'Κυνηγόσκυλο Μπασέ', value:16, en:'Basset Hound', alt:'κυνηγοσκυλο μπασε'},
    {label:'Βαυαρικό ορεινό κυνηγόσκυλο', value:17, en:'Bavarian Mountain Hound', alt:'βαυαρικο ορεινο κυνηγοσκυλο'},
    {label:'Μπιγκλ', value:18, en:'Beagle', alt:'μπιγκλ'},
    {label:'Γενειοφόρος Κόλεϊ', value:19, en:'Bearded Collie', alt:'γενειοφορος κολει'},
    {label:'Μποσερόν', value:20, en:'Beauceron', alt:'μποσερον'},
    {label:'Μπέντλινγκτον Τεριέ', value:21, en:'Bedlington Terrier', alt:'μπεντλινγκτον τεριε'},
    {label:'Βέλγικος Ποιμενικός Γκρένενταλ', value:22, en:'Belgian Shepherd Dog Groenendael', alt:'βελγικος ποιμενικος γκρενενταλ'},
    {label:'Βέλγικος Ποιμενικός Λακενουά', value:23, en:'Belgian Shepherd Dog Laekenois', alt:'βελγικος ποιμενικος λακενουα'},
    {label:'Βέλγικος Ποιμενικός Μαλινουά', value:24, en:'Belgian Shepherd Dog Malinois', alt:'βελγικος ποιμενικος μαλινουα'},
    {label:'Βέλγικος Ποιμενικός Τερβύρεν', value:25, en:'Belgian Shepherd Dog Tervueren', alt:'βελγικος ποιμενικος τερβυρεν'},
    {label:'Μπεργκαμάσκο', value:26, en:'Bergamasco', alt:'μπεργκαμασκο'},
    {label:'Ορεινός Σκύλος Βέρνης', value:27, en:'Bernese Mountain Dog', alt:'ορεινος σκυλος βερνης'},
    {label:'Μπισόν Φριζέ', value:28, en:'Bichon Frise', alt:'μπισον φριζε'},
    {label:'Μπλάντ Χάουντ', value:29, en:'Bloodhound', alt:'μπλαντ χαουντ'},
    {label:'Μπολονέζ', value:30, en:'Bolognese', alt:'μπολονεζ'},
    {label:'Μπόρντερ Κόλεϊ', value:31, en:'Border Collie', alt:'μποντερ κολει'},
    {label:'Μπόρντερ Τεριέ', value:32, en:'Border Terrier', alt:'μποντερ τεριε'},
    {label:'Μπορζόϊ', value:33, en:'Borzoi', alt:'μπορζοι'},
    {label:'Μπόστον Τεριέ', value:34, en:'Boston Terrier', alt:'μποστον τεριε'},
    {label:'Μπουβιέ της Φλάντρας', value:35, en:'Bouvier Des Flandres', alt:'μπουβιε της φλαντρας'},
    {label:'Μπόξερ', value:36, en:'Boxer', alt:'μποξερ'},
    {label:'Μπράκο Ιταλιάνο', value:37, en:'Bracco Italiano', alt:'μπρακο ιταλιανο'},
    {label:'Μπριάρ', value:38, en:'Briard', alt:'μπριαρ'},
    {label:'Μπρετόν', value:39, en:'Brittany', alt:'μπρετον'},
    {label:'Μπουλ Τεριέ', value:40, en:'Bull Terrier', alt:'μπουλ τεριε'},
    {label:'Μπουλ Τεριέ (Μινιατούρα)', value:41, en:'Bull Terrier Miniature', alt:'μπουλ τεριε (μινιατουρα)'},
    {label:'Μπουλ Ντογκ', value:42, en:'Bulldog', alt:'μπουλ ντογκ'},
    {label:'Μπουλμαστίφ', value:43, en:'Bullmastiff', alt:'μπουλμαστιφ'},
    {label:'Κερν Τεριέ', value:44, en:'Cairn Terrier', alt:'κερν τεριε'},
    {label:'Σκύλος της Χαναάν', value:45, en:'Canaan Dog', alt:'σκυλος της χανααν'},
    {label:'Καναδέζικο Έσκιμο', value:46, en:'Canadian Eskimo Dog', alt:'καναδεζικο εσκιμο'},
    {label:'Κάβαλιερ Κινγκ Τσαρλς Σπάνιελ', value:47, en:'Cavalier King Charles Spaniel', alt:'καβαλιερ κινγκ τσαρλς σπανιελ'},
    {label:'Τσέσκυ Τεριέ', value:48, en:'Cesky Terrier', alt:'τσεσκυ τεριε'},
    {label:'Τσιουάουα (Μακρύτριχο)', value:49, en:'Chihuahua (Long Coat)', alt:'τσιουαουα (μακρυτριχο)'},
    {label:'Τσιουάουα (Κοντότριχο)', value:50, en:'Chihuahua (Smooth Coat)', alt:'τσιουαουα (κοντοτριχο)'},
    {label:'Κινέζικο Κρέστιντ', value:51, en:'Chinese Crested', alt:'κινεζικο κρεστιντ'},
    {label:'Τσόου-Τσόου', value:52, en:'Chow Chow', alt:'τσοου-τσοου'},
    {label:'Κόλεϊ (Σκληρό τρίχωμα)', value:53, en:'Collie (Rough)', alt:'κολει (σκληρο τριχωμα)'},
    {label:'Κόλεϊ (Απαλό τρίχωμα)', value:54, en:'Collie (Smooth)', alt:'κολει (απαλο τριχωμα)'},
    {label:'Κοτόν ντε Τουλεάρ', value:55, en:'Coton De Tulear', alt:'κοτον ντε τουλεαρ'},
    {label:'Ντάτσχουντ (Μακρύτριχο)', value:56, en:'Dachshund (Long-Haired)', alt:'ντατσχουντ (μακρυτριχο), λουκανικο'},
    {label:'Ντάτσχουντ (Μινιατούρα Μακρύτριχο)', value:57, en:'Dachshund (Miniature Long-Haired)', alt:'ντατσχουντ (μινιατουρα μακρυτριχο), λουκανικο'},
    {label:'Ντάτσχουντ (Μινιατούρα Απαλό τρίχωμα)', value:58, en:'Dachshund (Miniature Smooth-Haired)', alt:'ντατσχουντ (μινιατουρα απαλο τριχωμα), λουκανικο'},
    {label:'Ντάτσχουντ (Μινιατούρα Σκληρό τρίχωμα)', value:59, en:'Dachshund (Miniature Wire-Haired)', alt:'ντατσχουντ (μινιατουρα σκληρό τριχωμα), λουκανικο'},
    {label:'Ντάτσχουντ (Απαλό τρίχωμα)', value:60, en:'Dachshund (Smooth-Haired)', alt:'ντατσχουντ (απαλο τριχωμα), λουκανικο'},
    {label:'Ντάτσχουντ (Σκληρό τρίχωμα)', value:61, en:'Dachshund (Wire-Haired)', alt:'ντατσχουντ (σκληρό τριχωμα), λουκανικο'},
    {label:'Δαλματίας', value:62, en:'Dalmatian', alt:'δαλματιας'},
    {label:'Ντάντι Ντινμοντ Τεριέ', value:63, en:'Dandie Dinmont Terrier', alt:'νταντι ντινμοντ τεριε'},
    {label:'Ντίρχαουντ', value:64, en:'Deerhound', alt:'ντιρχαουντ'},
    {label:'Ντόμπερμαν', value:65, en:'Dobermann', alt:'ντομπερμαν'},
    {label:'Ντογκ ντε Μπορντώ', value:66, en:'Dogue de Bordeaux', alt:'ντογκ ντε μπορντω'},
    {label:'Αγγλικό Σέττερ', value:67, en:'English Setter', alt:'αγγλικο σεττερ'},
    {label:'Αγγλικό Τόι Τεριέ', value:68, en:'English Toy Terrier (Black & Tan)', alt:'αγγλικο τοι τεριε'},
    {label:'Ορεινό Εστρέλλας', value:69, en:'Estrela Mountain Dog', alt:'ορεινο εστρελλας'},
    {label:'Φινλανδικό Λάπχουντ', value:70, en:'Finnish Lapphund', alt:'φινλανδικο λαπχουντ'},
    {label:'Φινλανδικό Σπιτζ', value:71, en:'Finnish Spitz', alt:'φινλανδικο σπιτζ'},
    {label:'Φοξ Τεριέ (Ίσιο τρίχωμα)', value:72, en:'Fox Terrier (Smooth Coat)', alt:'φοξ τεριε (ισιο τριχωμα)'},
    {label:'Φοξ Τεριέ (Σκληρό τρίχωμα)', value:73, en:'Fox Terrier (Wire Coat)', alt:'φοξ τεριε (σκληρο τριχωμα)'},
    {label:'Φοξχάουντ', value:74, en:'Foxhound', alt:'φοξχαουντ'},
    {label:'Γαλλικό Μπουλ Ντογκ', value:75, en:'French Bulldog', alt:'γαλλικο μπουλ ντογκ'},
    {label:'Γερμανικό Πίνσερ', value:76, en:'German Pinscher', alt:'γερμανικο πινσερ'},
    {label:'Γερμανικός Ποιμενικός', value:77, en:'German Shepherd Dog', alt:'γερμανικος ποιμενικος'},
    {label:'Γερμανικό Πόιντερ (Κοντότριχο)', value:78, en:'German Shorthaired Pointer', alt:'γερμανικο ποιντερ (κοντοτριχο)'},
    {label:'Γερμανικό Σπιτζ (Klein)', value:79, en:'German Spitz (Klein)', alt:'γερμανικο σπιτζ (klein)'},
    {label:'Γερμανικό Σπιτζ (Mittel)', value:80, en:'German Spitz (Mittel)', alt:'γερμανικο σπιτζ (mittel)'},
    {label:'Γερμανικό Πόιντερ (Σκληρό τρίχωμα)', value:81, en:'German Wirehaired Pointer', alt:'γερμανικο ποιντερ (σκληρο τριχωμα)'},
    {label:'Σνάουτσερ Γίγας', value:82, en:'Giant Schnauzer', alt:'σναουτσερ γιγας'},
    {label:'Γκόρντον Σέττερ', value:83, en:'Gordon Setter', alt:'γκορντον σεττερ'},
    {label:'Μεγάλος Μπλε της Γασκώνης', value:84, en:'Grand Bleu De Gascogne', alt:'μεγαλος μπλε της γασκωνης'},
    {label:'Γερμανικός Μολοσσός', value:85, en:'Great Dane', alt:'γερμανικος μολοσσος'},
    {label:'Γκρεϊχάουντ', value:86, en:'Greyhound', alt:'γκρειχαουντ'},
    {label:'Γκριφόν Βρυξελλών', value:87, en:'Griffon Bruxellois', alt:'γκριφον βρυξελλων'},
    {label:'Χάμιλτον Στοβάρε', value:88, en:'Hamiltonstovare', alt:'χαμιλτον στοβαρε'},
    {label:'Μπισόν Χαβανέζ', value:89, en:'Havanese', alt:'μπισον χαβανεζ'},
    {label:'Χόβαβαρτ', value:90, en:'Hovawart', alt:'χοβαβαρτ'},
    {label:'Ουγγρικό Καβάζ', value:91, en:'Hungarian Kuvasz', alt:'ουγγρικο καβαζ'},
    {label:'Ουγγρικό Πούλι', value:92, en:'Hungarian Puli', alt:'ουγγρικο πουλι'},
    {label:'Ουγγρικό Βίζλα', value:93, en:'Hungarian Vizsla', alt:'ουγγρικο βιζλα'},
    {label:'Ουγγρικό Βίζλα (Σκληρό τρίχωμα)', value:94, en:'Hungarian Wire Haired Vizsla', alt:'ουγγρικο βιζλα (σκληρο τριχωμα)'},
    {label:'Κυνηγόσκυλο της Ίμπιζα', value:95, en:'Ibizan Hound', alt:'κυνηγοσκυλο της ιμπιζα'},
    {label:'Ιρλανδικό Ερυθρόλευκο Σέττερ', value:96, en:'Irish Red & White Setter', alt:'ιρλανδικο ερυθρολευκο σεττερ'},
    {label:'Ιρλανδικό Σέττερ', value:97, en:'Irish Setter', alt:'ιρλανδικο σεττερ'},
    {label:'Ιρλανδικό Τεριέ', value:98, en:'Irish Terrier', alt:'ιρλανδικο τεριε'},
    {label:'Ιρλανδικό Λυκόσκυλο', value:99, en:'Irish Wolfhound', alt:'ιρλανδικο λυκοσκυλο'},
    {label:'Ιταλικό Γκρεϊχάουντ', value:100, en:'Italian Greyhound', alt:'ιταλικο γκρειχαουντ'},
    {label:'Ιταλικό Σπινόνε', value:101, en:'Italian Spinone', alt:'ιταλικο σπινονε'},
    {label:'Ιαπωνέζικο Ακίτα', value:102, en:'Japanese Akita', alt:'ιαπωνεζικο ακιτα'},
    {label:'Ιαπωνέζικο Τσιν', value:103, en:'Japanese Chin', alt:'ιαπωνεζικο τσιν'},
    {label:'Ιαπωνέζικο Σίμπα Ινού', value:104, en:'Japanese Shiba Inu', alt:'ιαπωνεζικο σιμπα ινου'},
    {label:'Ιαπωνέζικο Σπιτζ', value:105, en:'Japanese Spitz', alt:'ιαπωνεζικο σπιτζ'},
    {label:'Κίσχοντ', value:106, en:'Keeshond', alt:'κισχοντ'},
    {label:'Κέρι Μπλου Τεριέ', value:107, en:'Kerry Blue Terrier', alt:'κερι μπλου τεριε'},
    {label:'Κινγκ Τσαρλς Σπάνιελ', value:108, en:'King Charles Spaniel', alt:'κινγκ τσαρλς σπανιελ'},
    {label:'Κομοντόρ', value:109, en:'Komondor', alt:'κομοντορ'},
    {label:'Κοϊκερόντζ', value:110, en:'Kooikerhondje', alt:'κοικεροντζ'},
    {label:'Λέικλαντ Τεριέ', value:111, en:'Lakeland Terrier', alt:'λεικλαντ τεριε'},
    {label:'Λάνκασαϊρ Χίλερ', value:112, en:'Lancashire Heeler', alt:'λανκασαιρ χιλερ'},
    {label:'Λεονμπέργκερ', value:113, en:'Leonberger', alt:'λεονμπεργκερ'},
    {label:'Λάσα Άπσο', value:114, en:'Lhasa Apso', alt:'λασα απσο'},
    {label:'Λόουτσεν (Μικρό λιοντάρι)', value:115, en:'Lowchen (Little Lion Dog)', alt:'λοουτσεν (μικρο λιονταρι)'},
    {label:'Μαλτέζ', value:116, en:'Maltese', alt:'μαλτεζ'},
    {label:'Μάντσεστερ Τεριέ', value:117, en:'Manchester Terrier', alt:'μαντσεστερ τεριε'},
    {label:'Ποιμενικός Μαρεμάνο', value:118, en:'Maremma Sheepdog', alt:'ποιμενικος μαρεμανο'},
    {label:'Μαστίφ', value:119, en:'Mastiff', alt:'μαστιφ'},
    {label:'Πίνσερ μινιατούρα', value:120, en:'Miniature Pinscher', alt:'πινσερ μινιατουρα'},
    {label:'Σνάουτσερ μινιατούρα', value:121, en:'Miniature Schnauzer', alt:'σναουτσερ μινιατουρα'},
    {label:'Μυνστερλάντερ', value:122, en:'Munsterlander', alt:'μυνστερλαντερ'},
    {label:'Μαστίφ της Νάπολης', value:123, en:'Neapolitan Mastiff', alt:'μαστιφ της ναπολης'},
    {label:'Νιού Φάουντλαντ', value:124, en:'Newfoundland', alt:'νιου φαουντλαντ'},
    {label:'Νόρφολκ Τεριέ', value:125, en:'Norfolk Terrier', alt:'νορφολκ τεριε'},
    {label:'Νορβηγικό Μπουχούντ', value:126, en:'Norwegian Buhund', alt:'νορβηγικο μπουχουντ'},
    {label:'Ελκχάουντ Νορβηγίας', value:127, en:'Norwegian Elkhound', alt:'ελκχαουντ νορβηγιας'},
    {label:'Νόργουιτς Τεριέ', value:128, en:'Norwich Terrier', alt:'νοργουιτς τεριε'},
    {label:'Παλαιός Αγγλικός Ποιμενικός (Μπομπτέιλ)', value:129, en:'Old English Sheepdog (Bobtail)', alt:'παλαιος αγγλικος ποιμενικος (μπομπτειλ)'},
    {label:'Οτερχάουντ', value:130, en:'Otterhound', alt:'οτερχαουντ'},
    {label:'Παπιγιόν', value:131, en:'Papillon', alt:'παπιγιον'},
    {label:'Πάρσον Ράσελ Τεριέ', value:132, en:'Parson Russell Terrier', alt:'παρσον ρασελ τεριε'},
    {label:'Κυνηγόσκυλο του Φαραώ', value:133, en:'Pharaoh Hound', alt:'κυνηγοσκυλο του φαραω'},
    {label:'Πόιντερ', value:134, en:'Pointer', alt:'ποιντερ'},
    {label:'Πολωνικός Ποιμενικός Νιζινί', value:135, en:'Polish Lowland Sheepdog', alt:'πολωνικος ποιμενικος νιζινι'},
    {label:'Πομεράνιαν', value:136, en:'Pomeranian', alt:'πομερανιαν'},
    {label:'Πουντλ / Κανίς (Μινιατούρα)', value:137, en:'Poodle (Miniature)', alt:'πουντλ (μινιατουρα), κανις'},
    {label:'Πουντλ / Κανίς (Κανονικό)', value:138, en:'Poodle (Standard)', alt:'πουντλ (κανονικο), κανις'},
    {label:'Πουντλ / Κανίς (Τόι)', value:139, en:'Poodle (Toy)', alt:'πουντλ (τοι), κανις'},
    {label:'Πορτογαλικό Σκυλί του Νερού', value:140, en:'Portuguese Water Dog', alt:'πορτογαλικο σκυλι του νερου'},
    {label:'Παγκ', value:141, en:'Pug', alt:'παγκ'},
    {label:'Πυρηναίος Ορεινός', value:142, en:'Pyrenean Mountain Dog', alt:'πυρηναιος ορεινος'},
    {label:'Πυρηναίο Τσοπανόσκυλο (Μακρύτριχο)', value:143, en:'Pyrenean Sheepdog (Long Haired)', alt:'πυρηναιο τΤσοπανοσκυλο (μακρυτριχο)'},
    {label:'Ριτρίβερ (Κόλπος Τσέζαπικ)', value:144, en:'Retriever (Chesapeake Bay)', alt:'ριτριβερ (κολπος τσεζαπικ)'},
    {label:'Ριτρίβερ (Σγουρό τρίχωμα)', value:145, en:'Retriever (Curly Coated)', alt:'ριτριβερ (σγουρο τριχωμα)'},
    {label:'Ριτρίβερ (Επίπεδο τρίχωμα)', value:146, en:'Retriever (Flat Coated)', alt:'ριτριβερ (επίπεδο τριχωμα)'},
    {label:'Ριτρίβερ (Γκόλντεν)', value:147, en:'Retriever (Golden)', alt:'ριτριβερ (γκολντεν)'},
    {label:'Ριτρίβερ (Λαμπραντόρ)', value:148, en:'Retriever (Labrador)', alt:'ριτριβερ (λαμπραντορ)'},
    {label:'Ριτρίβερ (Νέας Σκωτίας)', value:149, en:'Retriever (Nova Scotia Duck Tolling)', alt:'ριτριβερ (νεας σκωτιας)'},
    {label:'Ροδεσιανό Ρίτζμπακ', value:150, en:'Rhodesian Rvaluegeback', alt:'ροδεσιανο ριτζμπακ'},
    {label:'Ροτβάιλερ', value:151, en:'Rottweiler', alt:'ροτβαιλερ'},
    {label:'Σαλούκι', value:152, en:'Saluki', alt:'σαλουκι'},
    {label:'Σαμογιέντ', value:153, en:'Samoyed', alt:'σαμογιεντ'},
    {label:'Σκίπερκι', value:154, en:'Schipperke', alt:'σκιπερκι'},
    {label:'Σνάουτσερ (Κανονικό)', value:155, en:'Schnauzer Standard', alt:'σναουτσερ (κανονικο)'},
    {label:'Σκοτσέζικο Τεριέ', value:156, en:'Scottish Terrier', alt:'σκοτσεζικο τεριε'},
    {label:'Σίλιχαμ Τεριέ', value:157, en:'Sealyham Terrier', alt:'σιλιχαμ τεριε'},
    {label:'Ιταλικός Ιχνηλάτης (Segugio)', value:158, en:'Segugio Italiano', alt:'ιταλικος ιχνηλατης (segugio)'},
    {label:'Σαρ Πέι', value:159, en:'Shar Pei', alt:'σαρ πει'},
    {label:'Ποιμενικός Σέτλαντ', value:160, en:'Shetland Sheepdog', alt:'ποιμενικος σετλαντ'},
    {label:'Σιχ Τσου', value:161, en:'Shih Tzu', alt:'σιχ τσου'},
    {label:'Σιβηρικό Χάσκυ', value:162, en:'Siberian Husky', alt:'σιβηρικο χασκυ'},
    {label:'Σκάι Τεριέ', value:163, en:'Skye Terrier', alt:'σκαι τεριε'},
    {label:'Σλούγκι', value:164, en:'Sloughi', alt:'σλουγκι'},
    {label:'Λειότριχο Λευκόμαλο Τεριέ', value:165, en:'Soft-Coated Wheaten Terrier', alt:'λειοτριχο λευκομαλο τεριε'},
    {label:'Σπάνιελ (Αμερικάνικο Κόκερ)', value:166, en:'Spaniel (American Cocker)', alt:'σπανιελ (αμερικανικο κοκερ)'},
    {label:'Σπάνιελ (Κλάμπερ)', value:167, en:'Spaniel (Clumber)', alt:'σπανιελ (κλαμπερ)'},
    {label:'Σπάνιελ (Κόκερ)', value:168, en:'Spaniel (Cocker)', alt:'σπανιελ (κοκερ)'},
    {label:'Σπάνιελ (Αγγλικό Σπρίνγκερ)', value:169, en:'Spaniel (English Springer)', alt:'σπανιελ (αγγλικο σπρινγκερ)'},
    {label:'Σπάνιελ (Φιλντ)', value:170, en:'Spaniel (Field)', alt:'σπανιελ (φιλντ)'},
    {label:'Σπάνιελ (Άιρις Γουότερ)', value:171, en:'Spaniel (Irish Water)', alt:'σπανιελ (αιρις γουοτερ)'},
    {label:'Σπάνιελ (Σάσεξ)', value:172, en:'Spaniel (Sussex)', alt:'σπανιελ (σασεξ)'},
    {label:'Σπάνιελ (Ουαλικό Σπρίνγκερ)', value:173, en:'Spaniel (Welsh Springer)', alt:'σπανιελ (ουαλικο σπρινγκερ)'},
    {label:'Ισπανικός Σκύλος του Νερού', value:174, en:'Spanish Water Dog', alt:'ισπανικος σκυλος του νερου'},
    {label:'Αγίου Βερνάρδου', value:175, en:'St. Bernard', alt:'αγιου βερναρδου'},
    {label:'Στάφορντσαϊρ Μπουλ Τεριέ', value:176, en:'Staffordshire Bull Terrier', alt:'σταφορντσαιρ μπουλ τεριε'},
    {label:'Σουηδικό Λάπχουντ', value:177, en:'Swedish Lapphund', alt:'σουηδικο λαπχουντ'},
    {label:'Σουηδικό Βάλχουντ', value:178, en:'Swedish Vallhund', alt:'σουηδικο βαλχουντ'},
    {label:'Θιβετιανό Μαστίφ', value:179, en:'Tibetan Mastiff', alt:'θιβετιανο μαστιφ'},
    {label:'Θιβετιανό Σπάνιελ', value:180, en:'Tibetan Spaniel', alt:'θιβετιανο σπανιελ'},
    {label:'Θιβετιανό Τεριέ', value:181, en:'Tibetan Terrier', alt:'θιβετιανο τεριε'},
    {label:'Βαϊμαράνερ', value:182, en:'Weimaraner', alt:'βαιμαρανερ'},
    {label:'Ουαλικό Κόργκι (Κάρντιγκαν)', value:183, en:'Welsh Corgi (Cardigan)', alt:'ουαλικο κοργκι (καρντιγκαν)'},
    {label:'Ουαλικό Κόργκι (Πέμπροκ)', value:184, en:'Welsh Corgi (Pembroke)', alt:'ουαλικο κοργκι (πεμπροκ)'},
    {label:'Ουαλικό Τεριέ', value:185, en:'Welsh Terrier', alt:'ουαλικο τεριε'},
    {label:'Γουέστ Χάιλαντ Άσπρο Τεριέ', value:186, en:'West Highland White Terrier', alt:'γουεστ χαιλαντ ασπρο τεριε'},
    {label:'Γουίπετ', value:187, en:'Whippet', alt:'γουιπετ'},
    {label:'Γιόρκσαϊρ Τεριέ', value:188, en:'Yorkshire Terrier', alt:'γιορκσαιρ τεριε'},
    {label:'Μεξικάνικος Άτριχος', value:189, en:'Mexican Hairless Dog', alt:'μεξικανικος ατριχος'},
    {label:'Τζακ Ράσελ Τεριέ', value:190, en:'Jack Russell Terrier', alt:'τζακ ρασελ τεριε'},
    {label:'Ποιμενικός της Σπάρτης', value:191, en:'Spartan Sheepdog', alt:'ποιμενικος της σπαρτης'},
    {label:'Ελληνικός Ποιμενικός', value:192, en:'Greek Sheepdog', alt:'ελληνικος ποιμενικος'},
    {label:'Ελληνικός Ιχνηλάτης', value:193, en:'Greek Pointer', alt:'ελληνικος ιχνηλατης'},
    {label:'Κρητικός Λαγωνικός/Ιχνηλάτης', value:194, en:'Cretan Greyhound/Pointer', alt:'κρητικος λαγωνικος/ιχνηλατης'},
    {label:'Κοκόνι', value:195, en:'Kokoni', alt:'κοκονι'},
    {label:'Λευκό Ελληνικό Τσοπανόσκυλο', value:196, en:'White Greek Hound', alt:'λευκο ελληνικο τσοπανοσκυλο'},
    {label:'Μολοσσός της Ηπείρου', value:197, en:'Molossus of Epirus', alt:'μολοσσος της ηπειρου'},
    {label:'Αλωπεκίς', value:198, en:'Alopekis', alt:'αλωπεκις'},
    {label:'Πίτμπουλ', value:199, en:'Pitbull', alt:'πιτμπουλ'}
];






// export default [
//     'Half-blood',
//     'Affenpinscher',
//     'Afghan Hound',
//     'Airedale Terrier',
//     'Alaskan Malamute',
//     'Anatolian Shepherd Dog',
//     'Australian Cattle Dog',
//     'Australian Shepherd Dog',
//     'Australian Silky Terrier',
//     'Australian Terrier',
//     'Basenji',
//     'Basset Bleu De Gascogne',
//     'Basset Fauve De Bretagne',
//     'Basset Griffon Vendeen (Grand)',
//     'Basset Griffon Vendeen (Petit)',
//     'Basset Hound',
//     'Bavarian Mountain Hound',
//     'Beagle',
//     'Bearded Collie',
//     'Beauceron',
//     'Bedlington Terrier',
//     'Belgian Shepherd Dog Groenendael',
//     'Belgian Shepherd Dog Laekenois',
//     'Belgian Shepherd Dog Malinois',
//     'Belgian Shepherd Dog Tervueren',
//     'Bergamasco',
//     'Bernese Mountain Dog',
//     'Bichon Frise',
//     'Bloodhound',
//     'Bolognese',
//     'Border Collie',
//     'Border Terrier',
//     'Borzoi',
//     'Boston Terrier',
//     'Bouvier Des Flandres',
//     'Boxer',
//     'Bracco Italiano',
//     'Briard',
//     'Brittany',
//     'Bull Terrier',
//     'Bull Terrier Miniature',
//     'Bulldog',
//     'Bullmastiff',
//     'Cairn Terrier',
//     'Canaan Dog',
//     'Canadian Eskimo Dog',
//     'Cavalier King Charles Spaniel',
//     'Cesky Terrier',
//     'Chihuahua (Long Coat)',
//     'Chihuahua (Smooth Coat)',
//     'Chinese Crested',
//     'Chow Chow (Rough)',
//     'Collie (Rough)',
//     'Collie (Smooth)',
//     'Coton De Tulear',
//     'Dachshund (Long-Haired)',
//     'Dachshund (Miniature Long-Haired)',
//     'Dachshund (Miniature Smooth-Haired)',
//     'Dachshund (Miniature Wire-Haired)',
//     'Dachshund (Smooth-Haired)',
//     'Dachshund (Wire-Haired)',
//     'Dalmatian',
//     'Dandie Dinmont Terrier',
//     'Deerhound',
//     'Dobermann',
//     'Dogue de Bordeaux',
//     'English Setter',
//     'English Toy Terrier (Black & Tan)',
//     'Estrela Mountain Dog',
//     'Finnish Lapphund',
//     'Finnish Spitz',
//     'Fox Terrier Smooth Coat',
//     'Fox Terrier Wire Coat',
//     'Foxhound',
//     'French Bulldog',
//     'German Pinscher',
//     'German Shepherd Dog',
//     'German Shorthaired Pointer',
//     'German Spitz (Klein)',
//     'German Spitz (Mittel)',
//     'German Wirehaired Pointer',
//     'Giant Schnauzer',
//     'Gordon Setter',
//     'Grand Bleu De Gascogne',
//     'Great Dane',
//     'Greyhound',
//     'Griffon Bruxellois',
//     'Hamiltonstovare',
//     'Havanese',
//     'Hovawart',
//     'Hungarian Kuvasz',
//     'Hungarian Puli',
//     'Hungarian Vizsla',
//     'Hungarian Wire Haired Vizsla',
//     'Ibizan Hound',
//     'Irish Red & White Setter',
//     'Irish Setter',
//     'Irish Terrier',
//     'Irish Wolfhound',
//     'Italian Greyhound',
//     'Italian Spinone',
//     'Japanese Akita',
//     'Japanese Chin',
//     'Japanese Shiba Inu',
//     'Japanese Spitz',
//     'Keeshond',
//     'Kerry Blue Terrier',
//     'King Charles Spaniel',
//     'Komondor',
//     'Kooikerhondje',
//     'Lakeland Terrier',
//     'Lancashire Heeler',
//     'Leonberger',
//     'Lhasa Apso',
//     'Lowchen (Little Lion Dog)',
//     'Maltese',
//     'Manchester Terrier',
//     'Maremma Sheepdog',
//     'Mastiff',
//     'Miniature Pinscher',
//     'Miniature Schnauzer',
//     'Munsterlander',
//     'Neapolitan Mastiff',
//     'Newfoundland',
//     'Norfolk Terrier',
//     'Norwegian Buhund',
//     'Norwegian Elkhound',
//     'Norwich Terrier',
//     'Old English Sheepdog (Bobtail)',
//     'Otterhound',
//     'Papillon',
//     'Parson Russell Terrier',
//     'Pharaoh Hound',
//     'Pointer',
//     'Polish Lowland Sheepdog',
//     'Pomeranian',
//     'Poodle (Miniature)',
//     'Poodle (Standard)',
//     'Poodle (Toy)',
//     'Portuguese Water Dog',
//     'Pug',
//     'Pyrenean Mountain Dog',
//     'Pyrenean Sheepdog (Long Haired)',
//     'Retriever (Chesapeake Bay)',
//     'Retriever (Curly Coated)',
//     'Retriever (Flat Coated)',
//     'Retriever (Golden)',
//     'Retriever (Labrador)',
//     'Retriever (Nova Scotia Duck Tolling)',
//     'Rhodesian Rvaluegeback',
//     'Rottweiler',
//     'Saluki',
//     'Samoyed',
//     'Schipperke',
//     'Schnauzer Standard',
//     'Scottish Terrier',
//     'Sealyham Terrier',
//     'Segugio Italiano',
//     'Shar Pei',
//     'Shetland Sheepdog',
//     'Shih Tzu',
//     'Siberian Husky',
//     'Skye Terrier',
//     'Sloughi',
//     'Soft-Coated Wheaten Terrier',
//     'Spaniel (American Cocker)',
//     'Spaniel (Clumber)',
//     'Spaniel (Cocker)',
//     'Spaniel (English Springer)',
//     'Spaniel (Field)',
//     'Spaniel (Irish Water)',
//     'Spaniel (Sussex)',
//     'Spaniel (Welsh Springer)',
//     'Spanish Water Dog',
//     'St. Bernard',
//     'Staffordshire Bull Terrier',
//     'Swedish Lapphund',
//     'Swedish Vallhund',
//     'Tibetan Mastiff',
//     'Tibetan Spaniel',
//     'Tibetan Terrier',
//     'Weimaraner',
//     'Welsh Corgi (Cardigan)',
//     'Welsh Corgi (Pembroke)',
//     'Welsh Terrier',
//     'West Highland White Terrier',
//     'Whippet',
//     'Yorkshire Terrier',
//     'Mexican Hairless Dog'
//     'Jack Russek Terrier',
//     'Spartan Sheepdog',
//     'Greek Sheepdog',
//     'Greek Pointer',
//     'Cretan Greyhound/Pointer',
//     'Kokoni',
//     'White Greek Hound',
//     'Molossus of Epirus',
//     'Alopekis',
//     'Pitbull'
// ];










// export default [
//     'Ημίαιμος',
//     'Αφενπίνσερ',
//     'Αφγανικό κυνηγόσκυλο',
//     'Έρντεϊλ Τεριέ',
//     'Μαλαμούτ Αλάσκας',
//     'Ποιμενικός Ανατολίας',
//     'Αυστραλιανός Σκύλος Βοοειδών',
//     'Ποιμενικός Αυστραλίας',
//     'Αυστραλιανό Σίλκι Τεριέ',
//     'Αυστραλιανό Τεριέ',
//     'Μπασέντζι',
//     'Μπασέ Μπλε της Γασκώνης',
//     'Μπασέ Φοβ της Βρετάνης',
//     'Μπασέ Γκριφόν Βανδέας (Μεγάλο)',
//     'Μπασέ Γκριφόν Βανδέας (Μικρό)',
//     'Κυνηγόσκυλο Μπασέ',
//     'Βαυαρικό ορεινό κυνηγόσκυλο',
//     'Μπιγκλ',
//     'Γενειοφόρος Κόλεϊ',
//     'Μποσερόν',
//     'Μπέντλινγκτον Τεριέ',
//     'Βέλγικος Ποιμενικός Γκρένενταλ',
//     'Βέλγικος Ποιμενικός Λακενουά',
//     'Βέλγικος Ποιμενικός Μαλινουά',
//     'Βέλγικος Ποιμενικός Τερβύρεν',
//     'Μπεργκαμάσκο',
//     'Ορεινός Σκύλος Βέρνης',
//     'Μπισόν Φριζέ',
//     'Μπλάντ Χάουντ',
//     'Μπολονέζ',
//     'Μπόρντερ Κόλεϊ',
//     'Μπόρντερ Τεριέ',
//     'Μπορζόϊ',
//     'Μπόστον Τεριέ',
//     'Μπουβιέ της Φλάντρας',
//     'Μπόξερ',
//     'Μπράκο Ιταλιάνο',
//     'Μπριάρ',
//     'Μπρετόν',
//     'Μπουλ Τεριέ',
//     'Μπουλ Τεριέ (Μινιατούρα)',
//     'Μπουλ Ντογκ',
//     'Μπουλμαστίφ',
//     'Κερν Τεριέ',
//     'Σκύλος της Χαναάν',
//     'Καναδέζικο Έσκιμο',
//     'Κάβαλιερ Κινγκ Τσαρλς Σπάνιελ',
//     'Τσέσκυ Τερριέ',
//     'Τσιουάουα (Μακρύτριχο)',
//     'Τσιουάουα (Κοντότριχο)',
//     'Κινέζικο Κρέστιντ',
//     'Τσόου-Τσόου',
//     'Κόλεϊ',
//     'Κόλεϊ (Λείο)',
//     'Κοτόν ντε Τουλεάρ',
//     'Ντάτσχουντ (Μακρύτριχο)',
//     'Ντάτσχουντ (Μινιατούρα Μακρύτριχο)',
//     'Ντάτσχουντ (Μινιατούρα Απαλό τρίχωμα)',
//     'Ντάτσχουντ (Μινιατούρα Σκληρό τρίχωμα)',
//     'Ντάτσχουντ (Απαλό τρίχωμα)',
//     'Ντάτσχουντ (Σκληρό τρίχωμα)',
//     'Δαλματίας',
//     'Ντάντι Ντινμοντ Τεριέ',
//     'Ντίρχαουντ',
//     'Ντόμπερμαν',
//     'Ντογκ ντε Μπορντώ',
//     'Αγγλικό Σέττερ',
//     'Αγγλικό Τόι Τεριέ',
//     'Ορεινό Εστρέλλας',
//     'Φινλανδικό Λάπχουντ',
//     'Φινλανδικό Σπιτζ',
//     'Φοξ Τεριέ (Ίσιο τρίχωμα)',
//     'Φοξ Τεριέ (Σκληρό τρίχωμα)',
//     'Φοξχάουντ',
//     'Γαλλικό Μπουλ Ντογκ',
//     'Γερμανικό Πίνσερ',
//     'Γερμανικόσ Ποιμενικός',
//     'Γερμανικό Πόιντερ (Κοντότριχο)',
//     'Γερμανικό Σπιτζ (Klein)',
//     'Γερμανικό Σπιτζ (Mittel)',
//     'Γερμανικό Πόιντερ (Σκληρό τρίχωμα)',
//     'Σνάουτσερ Γίγας',
//     'Γκόρντον Σέττερ',
//     'Μεγάλος Μπλε της Γασκώνης',
//     'Γερμανικός Μολοσσός',
//     'Γκρεϊχάουντ',
//     'Γκριφόν Βρυξελλών',
//     'Χάμιλτον Στοβάρε',
//     'Μπισόν Χαβανέζ',
//     'Χόβαβαρτ',
//     'Ουγγρικό Καβάζ',
//     'Ουγγρικό Πούλι',
//     'Ουγγρικό Βίζλα',
//     'Ουγγρικό Βίζλα (Σκληρό τρίχωμα)',
//     'Κυνηγόσκυλο της Ίμπιζα',
//     'Ιρλανδικό Ερυθρόλευκο Σέττερ',
//     'Ιρλανδικό Σέττερ',
//     'Ιρλανδικό Τεριέ',
//     'Ιρλανδικό Λυκόσκυλο',
//     'Ιταλικό Γκρεϊχάουντ',
//     'Ιταλικό Σπινόνε',
//     'Ιαπωνέζικο Ακίτα',
//     'Ιαπωνέζικο Τσιν',
//     'Ιαπωνέζικο Σίμπα Ινού',
//     'Ιαπωνέζικο Σπιτζ',
//     'Κίσχοντ',
//     'Κέρι Μπλου Τεριέ',
//     'Κινγκ Τσαρλς Σπάνιελ',
//     'Κομοντόρ',
//     'Κοϊκερόντζ',
//     'Λέικλαντ Τεριέ',
//     'Λάνκασαιρ Χίλερ',
//     'Λεονμπέργκερ',
//     'Λάσα Άπσο',
//     'Λόουτσεν (Μικρό λιοντάρι)',
//     'Μαλτέζ',
//     'Μάντσεστερ Τεριέ',
//     'Ποιμενικός Μαρεμάνο',
//     'Μαστίφ',
//     'Πίνσερ μινιατούρα',
//     'Σνάουτσερ μινιατούρα',
//     'Μυνστερλάντερ',
//     'Μαστίφ της Νάπολης',
//     'Νιού Φάουντλαντ',
//     'Νόρφολκ Τεριέ',
//     'Νορβηγικό Μπουχούντ',
//     'Ελκχάουντ Νορβηγίας',
//     'Νόργουιτς Τεριέ',
//     'Παλαιός Αγγλικός Ποιμενικός (Μπομπτέιλ)',
//     'Οτερχάουντ',
//     'Παπιγιόν',
//     'Πάρσον Ράσελ Τεριέ',
//     'Κυνηγόσκυλο του Φαραώ',
//     'Πόιντερ',
//     'Πολωνικός Ποιμενικός Νιζινί',
//     'Πομεράνιαν',
//     'Πουντλ (Μινιατούρα)',
//     'Πουντλ (Κανονικό)',
//     'Πουντλ (Τόι)',
//     'Πορτογαλικό Σκυλί του Νερού',
//     'Παγκ',
//     'Πυρηναίος Ορεινός',
//     'Πυρηναίο Τσοπανόσκυλο (Μακρύτριχο)',
//     'Ριτρίβερ (Κόλπος Τσέζαπικ)',
//     'Ριτρίβερ (Σγουρό τρίχωμα)',
//     'Ριτρίβερ (Επίπεδο τρίχωμα)',
//     'Ριτρίβερ (Γκόλντεν)',
//     'Ριτρίβερ (Λαμπραντόρ)',
//     'Ριτρίβερ (Νέας Σκωτίας)',
//     'Ροδεσιανό Ρίτζμπακ',
//     'Ροτβάιλερ',
//     'Σαλούκι',
//     'Σαμογιέντ',
//     'Σκίπερκι',
//     'Σνάουτσερ (Κανονικό)',
//     'Σκοτσέζικο Τεριέ Terrier',
//     'Σίλιχαμ Τεριέ',
//     'Ιταλικός Ιχνηλάτης (Segugio)',
//     'Σαρ Πέι',
//     'Ποιμενικός Σέτλαντ',
//     'Σιχ Τσου',
//     'Σιβηρικό Χάσκυ',
//     'Σκάι Τεριέ',
//     'Σλούγκι',
//     'Λειότριχο Λευκόμαλο Τεριέ',
//     'Σπάνιελ (Αμερικάνικο Κόκερ)',
//     'Σπάνιελ (Κλάμπερ)',
//     'Σπάνιελ (Κόκερ)',
//     'Σπάνιελ (Αγγλικό Σπρίνγκερ)',
//     'Σπάνιελ (Φιλντ)',
//     'Σπάνιελ (Άιρις Γουότερ)',
//     'Σπάνιελ (Σάσεξ)',
//     'Σπάνιελ (Ουαλικό Σπρίνγκερ)',
//     'Ισπανικός Σκύλος του Νερού',
//     'Αγίου Βερνάρδου',
//     'Στάφορντσαϊρ Μπουλ Τεριέ',
//     'Σουηδικό Λάπχουντ',
//     'Σουηδικό Βάλχουντ',
//     'Θιβετιανό Μαστίφ',
//     'Θιβετιανό Σπάνιελ',
//     'Θιβετιανό Τεριέ',
//     'Βαϊμαράνερ',
//     'Ουαλικό Κόργκι (Κάρντιγκαν)',
//     'Ουαλικό Κόργκι (Πέμπροκ)',
//     'Ουαλικό Τεριέ',
//     'Γουέστ Χάιλαντ Άσπρο Τεριέ',
//     'Γουίπετ',
//     'Γιόρκσαϊρ Τεριέ',
//     'Μεξικάνικος Άτριχος',
//     'Τζακ Ράσελ Τεριέ',
//     'Ποιμενικός της Σπάρτης',
//     'Ελληνικός Ποιμενικός',
//     'Ελληνικός Ιχνηλάτης',
//     'Κρητικός Λαγωνικός/Ιχνηλάτης',
//     'Κοκόνι',
//     'Λευκό Ελληνικό Τσοπανόσκυλο',
//     'Μολοσσός της Ηπείρου',
//     'Αλωπεκίς',
//     'Πίτμπουλ'
// ];