export default [
    {
        value:1,
        label:"Θηλυκό",
        en: "Female",
        img:require('../assets/genders/female-symbol.png')
    }, {
        value:2,
        label:"Αρσενικό",
        en: "Male",
        img:require('../assets/genders/male-symbol.png')
    }
];
