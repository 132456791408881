export default [
    {label:'Ημίαιμη', value:1, en:'Half-blood', alt:'ημιαιμη'},
    {label:'Αβάνας', value:2, en:'Havana Brown', alt:'αβανας'},
    {label:'Αβησσυνίας', value:3, en:'Abyssinian', alt:'αβησσυνιας'},
    {label:'Αιγυπτιακή Μάου', value:4, en:'Egyptian Mau', alt:'αιγυπτιακη μαου'},
    {label:'Αμερικάνικη Κερλ', value:5, en:'American Curl', alt:'αμερικανικη κερλ'},
    {label:'Αμερικάνικη Κοντότριχη', value:6, en:'American Shorthaired', alt:'αμερικανικη κοντοτριχη'},
    {label:'Αμερικάνικη Μπομπτέιλ', value:7, en:'American Bobtail', alt:'αμερικανικη μπομπτειλ'},
    {label:'Αμερικάνικη Σκληρότριχη', value:8, en:'American Wirehaired', alt:'αμερικανικη σκληροτριχη'},
    {label:'Ασιατική', value:9, en:'Asian', alt:'ασιατικη'},
    {label:'Αυστραλιανή Μιστ', value:10, en:'Australian Mist', alt:'αυστραλιανη μιστ'},
    {label:'Βιρμανίας', value:11, en:'Burmese', alt:'βιρμανιας'},
    {label:'Βομβάης', value:12, en:'Bombay cat', alt:'βομβαης'},
    {label:'Βρετανική Κοντότριχη', value:13, en:'British Shorthaired', alt:'βρετανικη κοντοτριχη'},
    {label:'Γερμανική Ρεξ', value:14, en:'German Rex', alt:'γερμανικη ρεξ'},
    {label:'Κάο Μάνι', value:15, en:'Khao Manee', alt:'καο μανι'},
    {label:'Κόρατ', value:16, en:'Korat', alt:'κορατ'},
    {label:'Μανξ', value:17, en:'Manx', alt:'μανξ'},
    {label:'Μάντσκιν', value:18, en:'Munchkin', alt:'μαντσκιν'},
    {label:'Μπομπτέιλ Κουρίλων', value:19, en:'Kurilian Bobtail', alt:'μπομπτειλ κουριλων'},
    {label:'Μπουρμίλα', value:20, en:'Burmilla', alt:'μπουρμιλα'},
    {label:'Ντέβον Ρεξ', value:21, en:'Devon Rex', alt:'ντεβον ρεξ'},
    {label:'Ντον Σφίγγα', value:22, en:'Don Sphynx', alt:'ντον σφιγγα'},
    {label:'Όκικατ', value:23, en:'Ocicat', alt:'οκικατ'},
    {label:'Οριεντάλ', value:24, en:'Oriental', alt:'οριενταλ'},
    {label:'Πίξι Μπομπ', value:25, en:'Pixie-bob', alt:'πιξι μπομπ'},
    {label:'Πίτερμπολντ', value:26, en:'Peterbald', alt:'πιτερμπολντ'},
    {label:'Ρεξ Κορνουάλης', value:27, en:'Cornish Rex', alt:'ρεξ κορνουαλης'},
    {label:'Ρωσική Μπλε', value:28, en:'Russian Blue', alt:'ρωσικη μπλε'},
    {label:'Σεϋχελλών', value:29, en:'Seychellois', alt:'σευχελλων'},
    {label:'Σιάμ', value:30, en:'Siamese', alt:'σιαμ'},
    {label:'Σιγκαπούρης', value:31, en:'Singapura', alt:'σιγκαπουρης'},
    {label:'Σνόουσου', value:32, en:'Snowshoe', alt:'σνοουσου'},
    {label:'Σοκόκε', value:33, en:'Sokoke', alt:'σοκοκε'},
    {label:'Τονκινέζα', value:34, en:'Tonkinese', alt:'τονκινεζα'},

    {label:'Νορβηγικού Δάσους', value:35, en:'Norwegian Forest cat', alt:'νορβηγικου δασους'},
    {label:'Ιαπωνική Μπομπτέιλ', value:36, en:'Japanese Bobtail', alt:'ιαπωνικη μπομπτειλ'},
    {label:'Ιερή Βιρμανίας', value:37, en:'Sacred Cat of Burma', alt:'ιερη βιρμανιας'},
    {label:'Λαπέρμ', value:38, en:'LaPerm', alt:'λαπερμ'},
    {label:'Λευκή Τουρκική Βαν', value:39, en:'White Turkish Van', alt:'λευκη τουρκικη βαν'},
    {label:'Μέιν Κουν', value:40, en:'Maine Coon', alt:'μειν κουν'},
    {label:'Νέβα Μάσκερεϊντ', value:41, en:'Neva Masquerade', alt:'νεβα μασκερειντ'},
    {label:'Ραγκαμάφιν', value:42, en:'Ragamuffin', alt:'ραγκαμαφιν'},
    {label:'Ράγκντολ', value:43, en:'Ragdoll', alt:'ραγκντολ'},
    {label:'Σαρτρέ', value:44, en:'Chartreux', alt:'σαρτρε'},
    {label:'Σέλκιρκ Ρεξ', value:45, en:'Selkirk Rex', alt:'σελκιρκ ρεξ'},
    {label:'Σιβηρίας', value:46, en:'Siberian cat', alt:'σιβηριας'},
    {label:'Σίμρικ', value:47, en:'Cymric cat', alt:'σιμρικ'},
    {label:'Σκωτσέζικη Στρέιτ', value:48, en:'Scottish Straight', alt:'σκωτσεζικη στρειτ'},
    {label:'Σκωτσέζικη Φολντ', value:49, en:'Scottish Fold', alt:'σκωτσεζικη φολντ'},
    {label:'Σομαλίας', value:50, en:'Somali cat', alt:'σομαλιας'},
    {label:'Τουρκική Αγκύρας', value:51, en:'Turkish Angora', alt:'τουρκικη αγκυρας'},
    {label:'Τουρκική Βαν', value:52, en:'Turkish Van', alt:'τουρκικη βαν'},

    {label:'Βρετανική Μακρύτριχη', value:53, en:'British Longhair', alt:'βρετανικη μακρυτριχη'},
    {label:'Μπαλινέζ', value:54, en:'Balinese', alt:'μπαλινεζ'},
    {label:'Περσίας', value:55, en:'Persian cat', alt:'περσιας'}
];

// export default [
//     'Ημίαιμη',
//     'Αβάνας',
//     'Αβησσυνίας',
//     'Αιγυπτιακή Μάου',
//     'Αμερικάνικη Κερλ',
//     'Αμερικάνικη Κοντότριχη',
//     'Αμερικάνικη Μπομπτέιλ',
//     'Αμερικάνικη Σκληρότριχη',
//     'Ασιατική',
//     'Αυστραλιανή Μιστ',
//     'Βιρμανική',
//     'Βομβάης',
//     'Βρετανική Κοντότριχη',
//     'Γερμανική Ρεξ',
//     'Κάο Μάνι',
//     'Κόρατ',
//     'Μανξ',
//     'Μάντσκιν',
//     'Μπομπτέιλ Κουρίλων',
//     'Μπουρμίλα',
//     'Ντέβον Ρεξ',
//     'Ντον Σφίγγα',
//     'Όκικατ',
//     'Οριεντάλ',
//     'Πίξι Μπομπ',
//     'Πίτερμπολντ',
//     'Ρεξ Κορνουάλης',
//     'Ρώσικη',
//     'Σεϋχελλών',
//     'Σιάμ',
//     'Σιγκαπούρης',
//     'Σνόουσουζ',
//     'Σοκόκε',
//     'Τονκινέζα',

//     'Γάτα Νορβηγικού Δάσους',
//     'Ιαπωνική Μπομπτέιλ',
//     'Ιερή Βιρμανίας',
//     'Λαπέρμ',
//     'Λευκή Τουρκική Βαν',
//     'Μέιν Κουν',
//     'Νέβα Μάσκερεϊντ',
//     'Ραγκαμάφιν',
//     'Ράγκντολ',
//     'Σαρτρέ',
//     'Σέλκιρκ Ρεξ',
//     'Σιβηρίας',
//     'Σίμρικ',
//     'Σκωτσέζικη Στρέιτ',
//     'Σκωτσέζικη Φολντ',
//     'Σομαλίας',
//     'Τουρκική Αγκύρας',
//     'Τουρκική Βαν',

//     'Βρετανική Μακρύτριχη',
//     'Μπαλινέζ',
//     'Περσίας'
// ];