import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { FreeMode, Navigation, Thumbs, Mousewheel } from "swiper";
import { IoAdd } from "react-icons/io5";
import Loader from "../../components/Loader";

import moment from 'moment';


const data = [
    {time:1643555177000, data:[{event:'Event 1', time:1643555177000},{event:'Event 2', time:1643573177000}]},
    {time:1643900777000, data:[{event:'Event 1', time:1643900777000},{event:'Event 2', time:1643907977000}]},
    {time:1648479977000, data:[{event:'Event 1', time:1648479977000},{event:'Event 2', time:1648494377000}]},
    {time:1648911977000, data:[{event:'Event 1', time:1648911977000},{event:'Event 2', time:1648915577000}]},
    {time:1658243177000, data:[{event:'Event 1', time:1658243177000},{event:'Event 2', time:1658253977000}]},
    {time:1662044777000, data:[{event:'Event 1', time:1662044777000},{event:'Event 2', time:1662048377000}]},
    {time:1662735977000, data:[{event:'Event 1', time:1662735977000},{event:'Event 2', time:1662743177000}]},
    {time:1665327977000, data:[{event:'Event 1', time:1665327977000},{event:'Event 2', time:1665345977000}]},
    {time:1665846377000, data:[{event:'Event 1', time:1665846377000},{event:'Event 2', time:1665857177000}]},
    {time:1668524777000, data:[{event:'Event 1', time:1668524777000},{event:'Event 2', time:1668539177000},{event:'Event 3', time:1668542777000}]},
    {time:1668786258000, data:[{event:'Event 1', time:1668786258000},{event:'Event 2', time:1668787577000}]},
    {time:1669131858000, data:[{event:'Event 1', time:1669131858000},{event:'Event 2', time:1669136777000}]},
    {time:1669650258000, data:[{event:'Event 1', time:1669650258000},{event:'Event 2', time:1669662377000}]},
    {time:1670082258000, data:[{event:'Event 1', time:1670082258000},{event:'Event 2', time:1670087177000}]},
    {time:1671551058000, data:[{event:'Event 1', time:1671551058000},{event:'Event 2', time:1671564737000}]},
]




function Calendar({}) {

//   const [ visiblePass, setVisiblePass ] = useState(false);
const [thumbsSwiper, setThumbsSwiper] = useState(null);
const swiper1Ref = useRef(null);
const swiper2Ref = useRef();

const [activeSlide, setActiveSlide] = useState()


const getClosest = (data, target) => 
  data.reduce((acc, obj) =>
     Math.abs(target - obj.time) < Math.abs(target - acc.time) ? obj : acc
  );

    function checkAge(i) {
        return i == getClosest(data,moment().format('x'));
    }

useEffect(() => {
    setActiveSlide(data.findIndex(checkAge))
    console.log(data.findIndex(checkAge))
}, [activeSlide]);



useEffect(() => {
    if (swiper1Ref.current !== null) {
      swiper1Ref.current.controller.control = swiper2Ref.current;
    }
}, []);



  return (
    activeSlide ? (
<>
    <div className='Calendar-Header'>
        <div className="Calendar-Header-Dates-Container">
            <Swiper
            initialSlide={activeSlide}
            // controller={{ control: firstSwiper }}
            onSwiper={setThumbsSwiper}
            touchStartPreventDefault={false}
            spaceBetween={5}
            slidesPerView={4}
            freeMode={false}
            watchSlidesProgress={true}
            slideToClickedSlide={true}
            mousewheel={true}
            modules={[FreeMode, Navigation, Thumbs, Mousewheel]}
            className="Calendar-Header-Dates"
            style={{
                "--swiper-navigation-color": "#6E9EF5",
                "--swiper-pagination-color": "#6E9EF5",
            }}
            breakpoints={{
                430: {
                  slidesPerView: 4,
                },
                540: {
                  slidesPerView: 5,
                },
                640: {
                  slidesPerView: 6,
                },
                768: {
                  slidesPerView: 7,
                }
            }}
            // navigation={true}
            >
                {
                    data.map((item) =>  
                    <SwiperSlide key={item.time} time={item.time}>
                        <div className="CardBlock Calendar-Header-Date-Container">
                            <div className="Calendar-Header-Date-Day-Name">{moment(item.time).format("dddd")}</div>
                            <div className="Calendar-Header-Date-Day">{moment(item.time).format("D")}</div>
                            <div className="Calendar-Header-Date-Month">{moment(item.time).format("MMM")}</div>
                        </div>
                    </SwiperSlide>
                  ) 
                }
            </Swiper>
        </div>
        <div className='button primary-button Calendar-Header-Add-Data'><IoAdd /> <span>Προσθέστε</span></div>
    </div>
      <Swiper
        initialSlide={activeSlide}
        onSwiper={(swiper) => {
            if (swiper1Ref.current !== null) {
            swiper1Ref.current = swiper;
            }
        }}
        touchStartPreventDefault={false}
        mousewheel={true}
        // controller={{ control: secondSwiper }}
        // style={{
        //   "--swiper-navigation-color": "#fff",
        //   "--swiper-pagination-color": "#fff",
        // }}
        spaceBetween={20}
        // navigation={true}
        thumbs={{
            swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Navigation, Thumbs, Mousewheel]}
        className="mySwiper2"
      >
        {
            data.map((item) =>  
                <SwiperSlide key={item.time} time={item.time}>
                    {
                        item.data.map((i) => 
                            <div key={i.time} className="CardBlock">
                                <p>{i.event}</p>
                                <p>{moment(i.time).format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
                            </div>
                        )
                    }
                    
                </SwiperSlide>
            ) 
        }

      </Swiper>
    </>
    ):(
        <Loader type='internal' />
    )
    
  );
}

export default Calendar;