import React, { useState } from 'react';
import DatePicker from 'react-date-picker';
import { RiCalendar2Line } from "react-icons/ri";
import moment from 'moment'

function DatePickerInput({label, formik, name, value, errors, touched, hasErrorOutside = null}) {
  
  const [dt, seDt] = useState(new Date());

  const OnDtChange = (v) => {
    seDt(v)
    formik.values[name] = moment(v).format('x')
  }

  return (
    <>
    <label className='Input-Label'>{label}</label>
    <div className={(errors && touched || hasErrorOutside) ? 'text-input-container text-input-error': 'text-input-container'}>
        <div className='text-input'>
            <DatePicker
                name={name}
                onChange={OnDtChange}
                value={dt}
                calendarIcon={<RiCalendar2Line fontSize={20} />}
                clearIcon={<svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>}
            />
        </div>
    </div>
    {(errors && touched) &&
        <div style={{ marginTop:3}}>
        <p className='errorText'>{errors}</p>
        </div>
    }
    </>
  );
}

export default DatePickerInput;