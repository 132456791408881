import React from 'react';

function Input({label, formik, placeholder, type, name, value, errors, touched, hasErrorOutside = null}) {

  return (
    <>
    <label className='Input-Label'>{label}</label>
    <div className={(errors && touched || hasErrorOutside) ? 'text-input-container text-input-error': 'text-input-container'}>
        <input className='text-input'
        name={name}
        type={type}
        placeholder={placeholder}
        style={{
            paddingLeft:18,
            paddingRight:18
        }}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={value}
        />
    </div>
    {(errors && touched) &&
        <div style={{ marginTop:3}}>
        <p className='errorText'>{errors}</p>
        </div>
    }
    </>
  );
}

export default Input;