import React, {useState} from 'react';
import ImgPickerUpdate from '../../components/Img-Picker-Update';

function PetOwnerProfileCardHeader({user}) {

  const [ confirmedShownImage, setConfirmedShownImage ] = useState(null);
  const [ changeProfileImg, setChangeProfileImg ] = useState(false);
  
  return (
    <>
        <div className='PetOwnerProfileCardHeader'>
            <div className='PetOwnerProfileCardImageContainer'>
                {/* <div className='PetOwnerProfileCardImage'>
                    <img src={user.petOwnerImage ? user.petOwnerImage : require("../../assets/defaultUser.png")} />
                </div> */}
                <div className='Image-Picker-Container'>
                    <ImgPickerUpdate
                        user={user}
                        confirmedShownImage={confirmedShownImage} 
                        setConfirmedShownImage={setConfirmedShownImage} 
                        width={130}
                        height={130}
                        borderRadius={130}
                        showPlaceHolder={false}
                        PreImage={user.petOwnerImage ? user.petOwnerImage : require("../../assets/defaultUser.png")}
                    />
                </div>
            </div>
            <div className='PetOwnerProfileCardHeaderText'>
              <h1>{user.petOwnerFullName}</h1>
              <h2>{user.petOwnerFullName.replace(/\s/g, '').toLowerCase()}</h2>
              <h2>{user.petOwnerLocation ? user.petOwnerLocation+', ': null} {user.petOwnerZipCode}</h2>
            </div>
            {/* <div className='PetOwnerProfileCardImageBg'>
              <img src={user.petOwnerImageBg ? user.petOwnerImageBg : require("../../assets/defaultUserCover.png")} />
            </div>
            <div className='PetOwnerProfileCardHeaderContent'>
              <div className='PetOwnerProfileCardImage'>
                <img src={user.petOwnerImage ? user.petOwnerImage : require("../../assets/defaultUser.png")} />
              </div>
              <h1>{user.petOwnerFullName}</h1>
            </div> */}
        </div>
    </>
  );
}

export default PetOwnerProfileCardHeader;