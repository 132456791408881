export default [
    {label:'Δεν γνωρίζω', value:1, en:'I don\'t know'},
    {label:'DEA 1 Αρνητικό', value:2, en:'DEA 1 Negative'},
    {label:'DEA 1 Θετικό', value:3, en:'DEA 1 Positive'},
    {label:'DEA 1.1', value:4, en:'DEA 1.1'},
    {label:'DEA 1.2', value:5, en:'DEA 1.2'},
    {label:'DEA 1.3', value:6, en:'DEA 1.3'},
    {label:'DEA 3', value:7, en:'DEA 3'},
    {label:'DEA 7', value:8, en:'DEA 7'},
];
