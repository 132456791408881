import React, { useRef, useEffect, useState } from 'react';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

function SelectField({
    animalType = 0,
    isDependent = false, 
    loadedContent = null, 
    label, 
    isMulti = false, 
    isDisabled = false, 
    formik, 
    data, 
    name, 
    closeOnBlur, 
    placeholder, 
    errors, 
    touched, 
    hasErrorOutside = null
}) {
  
  const animatedComponents = makeAnimated();
  const selectInputRef = useRef();

    useEffect(() => {
        if(isDependent && animalType !== 0){
            if(isMulti){
                if(selectInputRef.current.getValue().length > 0){
                    selectInputRef.current.clearValue();
                }
            }else{
                selectInputRef.current.clearValue();
            }
        }
    }, [animalType]);


    const [multiTags, setMultiTags] = useState([])

    useEffect(() => {
        // console.log(formik.values.breed)
    }, [multiTags]);


    const [loadedData, setLoadedData] = useState(loadedContent)

    useEffect(() => {
        if(loadedContent){
            setLoadedData(loadedContent)
        }
    }, [loadedContent, data]);



  return (
    <>
    <label className='Input-Label'>{label}</label>
    <div className={(errors && touched || hasErrorOutside) ? 'text-input-container text-input-error': 'text-input-container'}>
        <Select
            isDisabled={isDisabled}
            ref={selectInputRef}
            className='Select-Box'
            placeholder={placeholder}
            closeMenuOnSelect={closeOnBlur} 
            components={animatedComponents}
            defaultValue={loadedData ? data ? data.find(data => data.value === loadedData) : null : null}
            name={name}
            isMulti={isMulti}
            options={data}
            onChange={selectedOption =>{
                    if(selectedOption){
                        isMulti ? (
                            formik.setFieldValue(name, selectedOption.map((i)=>{
                                return i.value
                            }))
                        ):(
                            formik.setFieldValue(name, selectedOption.value)
                        )
                    }
                }
            }
            value={(data ? data.find(data => data.value === name) : '')}
        />
    </div>
    {(errors && touched) &&
        <div style={{ marginTop:3}}>
        <p className='errorText'>{errors}</p>
        </div>
    }
    </>
  );
}

export default SelectField;