import React, { useRef, useEffect, useContext, useState } from 'react';
import { Outlet, Link } from "react-router-dom";
import PetOwnerProfileCard from './PetOwnerProfileCard';
import Loader from '../../components/Loader';

import { UserContext } from '../../context/UserContext'

function PetOwnerProfile() {

  const { user } = useContext(UserContext);

  // useEffect(() => {
  //   console.log(user)
  // }, [user]);

  return (
    <>
      <div className="Main-Container-Outer"> 
        <div className="PetOwnerProfilePage Main-Container"> 
          {
            user ? (
              <PetOwnerProfileCard user={user} />
            ):(
              <Loader type='internal' />
            )
          }

          <Outlet />
        </div>
      </div>
    </>
  );
}

export default PetOwnerProfile;