export default [
    {label:'Μαύρο', value:1, en:'Black', alt:'μαυρο', rgb:'#000000'},
    {label:'Λευκό', value:2, en:'White', alt:'λευκό, ασπρο', rgb:'#ffffff'},
    {label:'Καφέ', value:3, en:'Brown', alt:'καφε', rgb:'#A52A2A'},
    {label:'Ανοιχτό καφέ', value:4, en:'Light Brown', alt:'ανοιχτο καφε', rgb:'#b5651d'},
    {label:'Σκούρο καφέ', value:5, en:'Dark Brown', alt:'σκουρο καφε', rgb:'#654321'},
    {label:'Τζίντζερ', value:6, en:'Ginger', alt:'τζιντζερ', rgb:'#96665a'},
    {label:'Πορτοκαλί', value:7, en:'Orange', alt:'πορτοκαλι', rgb:'#FFA500'},
    {label:'Χρυσό', value:8, en:'Gold', alt:'χρυσο', rgb:'#FFD700'},
    {label:'Ασημί', value:9, en:'Silver', alt:'ασημι', rgb:'#C0C0C0'},
    {label:'Γκρι', value:10, en:'Gray', alt:'γκρι', rgb:'#808080'},
    {label:'Ανοιχτό γκρι', value:11, en:'Light Gray', alt:'ανοιχτο γκρι', rgb:'#D3D3D3'},
    {label:'Σκούρο γκρι', value:12, en:'Dark Gray', alt:'σκουρο γκρι', rgb:'#696969'},
    {label:'Μπεζ', value:13, en:'Beige', alt:'μπεζ', rgb:'#E8D3B9'},
    {label:'Κρεμ', value:14, en:'Cream', alt:'κρεμ', rgb:'#FFFDD0'},
    {label:'Ροζ', value:15, en:'Pink', alt:'ροζ', rgb:'#FFC0CB'},
    {label:'Κόκκινο', value:16, en:'Red', alt:'κοκκινο', rgb:'#FF0000'},
    {label:'Μπλε', value:17, en:'Blue', alt:'μπλε', rgb:'#0000FF'},
    {label:'Κίτρινο', value:18, en:'Yellow', alt:'κιτρινο', rgb:'#FFFF00'},
    {label:'Πράσινο', value:19, en:'Green', alt:'πρασινο', rgb:'#00FF00'},
    {label:'Μωβ', value:20, en:'Purple', alt:'μωβ', rgb:'#E6E6FA'},
    {label:'Κανελί', value:21, en:'Cinnamon', alt:'κανελι, κανελα', rgb:'#C58C66'},
];
