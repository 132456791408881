import React, { useRef, useEffect, useContext, useState } from 'react';
import { Outlet, Link } from "react-router-dom";
import { useFormik } from 'formik'
import * as yup from 'yup'
import Lottie from "lottie-react";
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import lot from '../../lf30_editor_yzkzhxlc.json'
import googleplay from '../../GooglePlay.png';
import appstore from '../../AppStore.png';
import adopt from '../../assets/steps/adopt.svg';
import bell from '../../assets/steps/bell.svg';
import hangout from '../../assets/steps/hangout.svg';
import healthcard from '../../assets/steps/health-card.svg';
import marketplace from '../../assets/steps/marketplace.svg';
import videocall from '../../assets/steps/video-call.svg';
import Input from '../../components/Input';
import Password from '../../components/Password';

import { IoAlertCircleSharp } from "react-icons/io5";


import { UserContext } from '../../context/UserContext';
// import './index.css';

function Login() {

  const { errorCallMsg, setErrorCallMsg, loginUser } = useContext(UserContext);

  const loginValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Παρακαλώ εισάγετε διεύθυνση email')
      .required('Η διεύθυνση email είναι υποχρεωτική'),
    password: yup
      .string()
      .required('Ο κωδικός είναι υποχρεωτικός'),
  })

  const [ visiblePass, setVisiblePass ] = useState(false);


  const formik = useFormik({
    validationSchema: loginValidationSchema,
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: values => {
      // console.log(values.email, values.password)
      loginUser(values.email, values.password)
    },
  });


  const [loadedLoginPage, setLoadedLoginPage] = useState(false)

  useEffect(() => {
    setLoadedLoginPage(true)
  }, []);

  return (
    <div className={loadedLoginPage ? "Login-Container-Outer active" : "Login-Container-Outer"}>
      <div className="Login-Container">
        
        <div className={loadedLoginPage ? "Login-Box active" : "Login-Box"}>
          <div style={{padding:1}}></div>
          <div>
            <div className={loadedLoginPage ? "Logo-Top active" : "Logo-Top"}>
              <svg width="120" viewBox="0 0 218.6 86.6">
                  <path className="st0" fill="#7474A3" d="M0,1.7h16.7v66H0V1.7z"/>
                  <path className="st1" fill="#54B754" d="M160.8,33.2h-10.9v34.6h-16.7V33.2h-8l0-13.1h8.3v-4.6C133.4,5.7,138.7,0,149,0h11.7v12.8h-7.4
                  c-2.5,0-4,1.5-4,3.9v3.3h11.4V33.2z"/>
                  <path className="st2" fill="#FF6666" d="M218.6,20.1l-20.3,46.4c-6.5,14.8-12.6,20.2-25.1,20.2h-5.7V72.3h4.6c6.4,0,8.3-1.7,10.6-7.7l-20-44.5h18.2
                  l10.3,26.7l9.6-26.7H218.6z"/>
                  <circle className="st3" fill="#6E9EF5" cx="45.8" cy="43.5" r="25.8"/>
                  <circle className="st4" fill="#FFCC33" cx="99.3" cy="43.5" r="25.8"/>
              </svg>
            </div>
            <h3 className="Title">Συνδεθείτε με<br></br>τον λογαριασμό σας &#128527;</h3>
            <div className='seperator-xxxl'></div>

            <form onSubmit={formik.handleSubmit} onChange={()=>setErrorCallMsg(null)}>
              <Input 
                  label='Διεύθυνση email' 
                  formik={formik} 
                  placeholder="name@email.com" 
                  type="email" 
                  name="email" 
                  value={formik.values.email} 
                  errors={formik.errors.email}
                  touched={formik.touched.email}
                  hasErrorOutside={errorCallMsg}
              />
              <div className='seperator-l'></div>
              <Password
                  label='Κωδικός'
                  formik={formik}
                  name='password'
                  value={formik.values.password}
                  errors={formik.errors.password}
                  touched={formik.touched.password}
                  forgotpassword={true}
                  hasErrorOutside={errorCallMsg}
              />
              {errorCallMsg ? (
                <>
                  <div className='seperator-l'></div>
                  <div style={{ marginTop:3}}>
                    <p className='errorText'><span style={{display:'flex', alignItems:'center'}}><IoAlertCircleSharp style={{marginRight:6}} /> {errorCallMsg}</span></p>
                  </div>
                </>
              ):null}
              <div className='seperator-xxl'></div>

              <button type="submit" className='button primary-button'>
                Σύνδεση
              </button>

              <div className='seperator-xl'></div>
 
            </form>

            <Link className='toRegisterLink' to="/register">Δεν έχετε λογαριασμό; <strong><u>Εγγραφείτε εδώ</u></strong></Link>


          </div>

          <div className='Footer-Bottom'>
            <div className='Mobile-Stores-Container'>
              <div className="Mobile-Stores-Prompt">Κατεβάστε το loofy στο κινητό σας</div>
              <div className='Mobile-Stores'>
                <Link className='storesLink' to="#">
                  <img src={googleplay} alt="googleplay"/>
                </Link>
                <Link className='storesLink' to="#">
                  <img src={appstore} alt="appstore" />
                </Link>
              </div>
            </div>
            <div className='Bottom-Links'>
              <span>© 2022 loofy</span> · <Link to="#">Όροι χρήσης</Link> · <Link to="#">Πολιτική προστασίας</Link>
            </div>
          </div>

        </div>
      </div>


      <div className={loadedLoginPage ? 'Poster-Container active' : 'Poster-Container' }>
        <Lottie className={loadedLoginPage ? 'LottieLogin active' : 'LottieLogin'} animationData={lot} loop={true} />
        <div className={loadedLoginPage ? "bubbles-container active" : "bubbles-container"}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={loadedLoginPage ? 'Welcome-Text active' : 'Welcome-Text'}>

          <svg className="login-logo" width="180" viewBox="0 0 218.6 86.6">
              <path className="st0" fill="#7474A3" d="M0,1.7h16.7v66H0V1.7z"/>
              <path className="st1" fill="#54B754" d="M160.8,33.2h-10.9v34.6h-16.7V33.2h-8l0-13.1h8.3v-4.6C133.4,5.7,138.7,0,149,0h11.7v12.8h-7.4
              c-2.5,0-4,1.5-4,3.9v3.3h11.4V33.2z"/>
              <path className="st2" fill="#FF6666" d="M218.6,20.1l-20.3,46.4c-6.5,14.8-12.6,20.2-25.1,20.2h-5.7V72.3h4.6c6.4,0,8.3-1.7,10.6-7.7l-20-44.5h18.2
              l10.3,26.7l9.6-26.7H218.6z"/>
              <circle className="st3" fill="#6E9EF5" cx="45.8" cy="43.5" r="25.8"/>
              <circle className="st4" fill="#FFCC33" cx="99.3" cy="43.5" r="25.8"/>
          </svg> 

          <h1>Καλωσήρθατε στο loofy!</h1>
          <h2>Την πληρέστερη πλατφόρμα για τα κατοικίδια σας!</h2>
          <div className={loadedLoginPage ? 'Login-Swiper active' : 'Login-Swiper'}>
            <Swiper
              speed={1000}
              modules={[Autoplay]}
              autoHeight={true}
              direction={"vertical"}
              spaceBetween={50}
              slidesPerView={1}
              // pagination={{ clickable: true }}
              // onSlideChange={() => console.log('slide change')}
              // onSwiper={(swiper) => console.log(swiper)}
              autoplay={{
                disableOnInteraction: false, // Optional, but recommended
                delay: 3000,
                pauseOnMouseEnter: true,
              }}
              loop={true}
            >
              <SwiperSlide><div className='Slide-Content'><div><img src={healthcard} alt="healthcard"/></div>Με το ηλεκτρονικό βιβλιάριο υγείας μένετε πάντα ενημερωμένοι για την υγεία του κατοικίδιου σας</div></SwiperSlide>
              <SwiperSlide><div className='Slide-Content'><div><img src={bell} alt="bell" /></div>Χάθηκε; Βρέθηκε; Εντοπίστηκε φόλα; Ειδοποιήστε και ειδοποιηθείτε για οτιδήποτε συμβεί στην περιοχή σας!</div></SwiperSlide>
              <SwiperSlide><div className='Slide-Content'><div><img src={videocall} alt="videocall" /></div>Βρείτε τον ιδανικό εκπαιδευτή για το ζωάκι σας και κλείστε άμεσα online συνεδρίες, όπου και αν βρίσκεστε!</div></SwiperSlide>
              <SwiperSlide><div className='Slide-Content'><div><img src={marketplace} alt="marketplace" /></div>Το μεγαλύτερο marketplace για τους μικρούς μας φίλους με εξατομικευμένα προϊόντα και υπηρεσίες</div></SwiperSlide>
              <SwiperSlide><div className='Slide-Content'><div><img src={hangout} alt="hangout" /></div>Δημιουργήστε εκδηλώσεις, βόλτες και γνωρίστε απίθανους φίλους και εκπληκτικά μέρη για το κατοικίδιο σας</div></SwiperSlide>
              <SwiperSlide><div className='Slide-Content'><div><img src={adopt} alt="adopt" /></div>Υιοθετήστε ζώα συντροφιάς, αναζητήστε και βρείτε εύκολα το νέο μέλος της οικογένειας σας</div></SwiperSlide>
            </Swiper>
          </div>

        </div>
      </div>

      <Outlet />
    </div>
  );
}

export default Login;