import React, { useRef, useEffect, useContext, useState } from 'react';
import { Outlet, Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { IoNewspaperOutline, IoImagesOutline, IoVideocamOutline, IoCalendarNumberOutline, IoPawOutline, IoInformationCircleOutline, IoAdd } from "react-icons/io5";
import EmptyPets from '../../assets/empty_pets_colored.svg'
import PetOwnerProfileCardHeader from './PetOwnerProfileCardHeader';
import PetOwnerPersonalInfoCard from './PetOwnerPersonalInfoCard';
import PetOwnerPetsCard from './PetOwnerPetsCard';
import PetOwnerCoOwnersCard from './PetOwnerCoOwnersCard';


function PetOwnerProfileCard({user}) {

  return (
    <>
        <div className='PetOwnerProfileCard'>
          <PetOwnerProfileCardHeader user={user} />
          <div className='PetOwnerProfileCardBody'> 
            <PetOwnerPersonalInfoCard user={user} />
            <PetOwnerPetsCard user={user} />
            <PetOwnerCoOwnersCard user={user} />

            <div className='PetOwnerProfileCardTabs'>
              <Tabs onSelect={(index) => console.log(index)}>
                <div className='react-tabs-container'>
                  <TabList>
                    <Tab className='react-tabs__tab noselect'><IoNewspaperOutline /> <span>Δημοσιεύσεις</span></Tab>
                    <Tab className='react-tabs__tab noselect'><IoImagesOutline /> <span>Φωτογραφίες</span></Tab>
                    <Tab className='react-tabs__tab noselect'><IoVideocamOutline /> <span>Βίντεο</span></Tab>
                    <Tab className='react-tabs__tab noselect'><IoInformationCircleOutline /> <span>Σχετικά</span></Tab>
                  </TabList>
                </div>

                <TabPanel>
                <div className='CardBlock' style={{height:600}}>
                  <div className='seperator-s'></div>
                  <h5 className='CardBlockTitle'>Post 1</h5>
                  <div className='seperator-xl'></div>
                </div>
                <div className='CardBlock' style={{height:600}}>
                  <div className='seperator-s'></div>
                  <h5 className='CardBlockTitle'>Post 2</h5>
                  <div className='seperator-xl'></div>
                </div>
                <div className='CardBlock' style={{height:600}}>
                  <div className='seperator-s'></div>
                  <h5 className='CardBlockTitle'>Post 3</h5>
                  <div className='seperator-xl'></div>
                </div>
                </TabPanel>
                <TabPanel>
                  <h2>Any content 2</h2>
                </TabPanel>
                <TabPanel>
                  <h2>Any content 3</h2>
                </TabPanel>
                <TabPanel>
                  <h2>Any content 4</h2>
                </TabPanel>
              </Tabs>
            </div>

          </div>
          {/* <div className='PetOwnerProfileCardTabs'>
            <Tabs onSelect={(index) => console.log(index)}>
              <div className='react-tabs-container'>
                <TabList>
                  <Tab className='react-tabs__tab noselect'><IoNewspaperOutline /> <span>Δημοσιεύσεις</span></Tab>
                  <Tab className='react-tabs__tab noselect'><IoPawOutline /> <span>Κατοικίδια</span></Tab>
                  <Tab className='react-tabs__tab noselect'><IoImagesOutline /> <span>Φωτογραφίες</span></Tab>
                  <Tab className='react-tabs__tab noselect'><IoVideocamOutline /> <span>Βίντεο</span></Tab>
                  <Tab className='react-tabs__tab noselect'><IoInformationCircleOutline /> <span>Σχετικά</span></Tab>
                </TabList>
              </div>

              <TabPanel>
                <h2>Any content 1</h2>
              </TabPanel>
              <TabPanel>
                <h2>Any content 2</h2>
              </TabPanel>
              <TabPanel>
                <h2>Any content 3</h2>
              </TabPanel>
              <TabPanel>
                <h2>Any content 4</h2>
              </TabPanel>
              <TabPanel>
                <h2>Any content 5</h2>
              </TabPanel>
            </Tabs>
          </div> */}
        </div>
    </>
  );
}

export default PetOwnerProfileCard;