import React, { useRef, useEffect, useContext, useState } from 'react';
import { Outlet, Link } from "react-router-dom";
import dog1 from '../../../assets/smallPetIcons/dog1.svg';
import dog2 from '../../../assets/smallPetIcons/dog2.svg';
import dog3 from '../../../assets/smallPetIcons/dog3.svg';
import dog4 from '../../../assets/smallPetIcons/dog4.svg';
import cat1 from '../../../assets/smallPetIcons/cat1.svg';
import cat2 from '../../../assets/smallPetIcons/cat2.svg';
import cat3 from '../../../assets/smallPetIcons/cat3.svg';
import cat4 from '../../../assets/smallPetIcons/cat4.svg';

function SmallPetsAnimation() {

    const [emoji, setEmoji] = useState(7);
    const emojiArray = [dog1,cat1,dog2,cat2,dog3,cat3,dog4,cat4]

    var curEmoji = -1;

    useEffect(() => {

        const interval = setInterval(() => {
            ++curEmoji;
            if (curEmoji >= emojiArray.length) {
                curEmoji = 0;
            }
            setEmoji(curEmoji)
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    
    useEffect(() => {
        
    }, []);

  return (
    emojiArray.map((item, i)=>(
        <img alt='SmallPetIcon' key={i} className={emoji === i ? 'SmallPetIcon show' : 'SmallPetIcon'} src={item} />
    ))
  );
}

export default SmallPetsAnimation;