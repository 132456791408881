import React, {useState} from 'react';
import { IoAdd } from "react-icons/io5";

function PetOwnerCoOwnersCard({user}) {

//   const [ visiblePass, setVisiblePass ] = useState(false);

  return (
    <>
        <div className='CardBlock'>
            <div className='seperator-s'></div>
            <h5 className='CardBlockTitle'>Συνιδιοκτησία</h5>
            <div className='seperator-l'></div>
            {/* <img src={EmptyPets} style={{maxWidth:300, marginLeft:'auto', marginRight:'auto', display:'block', opacity:0.7}} /> */}
            <h4 className='Empty-Text' style={{textAlign:'center'}}>Δεν υπάρχουν συνιδιοκτήτες</h4>
            <div className='seperator-xxl'></div>
            <div className='button-group'>
                <button type="button" className='button primary-button md-button outlined-button'>
                <IoAdd size={24} /> Προσθέστε συνιδιοκτήτη
                </button>
                <button type="button" className='button primary-button md-button outlined-button'>
                Ζητήστε συνιδιοκτησία
                </button>
            </div>
            <div className='seperator-s'></div>
        </div>
    </>
  );
}

export default PetOwnerCoOwnersCard;