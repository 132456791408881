import React, {useState, useContext, useEffect} from 'react';
// import { FiEye, FiEyeOff } from "react-icons/fi";
import { useFormik } from 'formik'
import * as yup from 'yup'

import Input from '../../components/Input';
import Toggle from '../../components/Toggle';
import GetGeolocation from '../../components/GetGeolocation';

import {UserContext} from '../../context/UserContext';

function PetOwnerPersonalInfoCard({user}) {

    const { updateUser } = useContext(UserContext);

  const [ editMode, setEditMode ] = useState(false);

  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  const [userLocation, setUserLocation] = useState(null);
  const [address, setAddress] = useState(null)
  const [tk, setTk] = useState(null)


  const registerValidationSchema = yup.object().shape({
    name: yup
        .string()
        .required('Το ονοματεπώνυμο είναι υποχρεωτικό'),
    email: yup
        .string()
        .email('Παρακαλώ εισάγετε διεύθυνση email')
        .required('Η διεύθυνση email είναι υποχρεωτική'),
    tk: yup
        .string()
        .matches(/^[0-9]+$/, "Ο ταχυδρομικός κώδικας αποτελείται μόνο από αριθμούς")
        .min(5, ({ min }) => 'Ο ταχυδρομικός κώδικας πρέπει να αποτελείται από 5 ψηφία')
        .max(5, ({ max }) => 'Ο ταχυδρομικός κώδικας πρέπει να αποτελείται από 5 ψηφία')
        .required('Ο ταχυδρομικός κώδικας είναι υποχρεωτικός'),
    location: yup
        .string()
        // .required('Το ονοματεπώνυμο είναι υποχρεωτικό'),
    })

    async function UserUpdate (t,n,e,la,lo,im,loc,tk) {
        return updateUser(t,n,e,la,lo,im,loc,tk);
    }

  const formik1 = useFormik({
    validationSchema: registerValidationSchema,
    initialValues: {
      name: user.petOwnerFullName,
      email: user.petOwnerEmail,
      lat: user.petOwnerGPSLocationLat,
      lng: user.petOwnerGPSLocationLng,
      location: user.petOwnerLocation ? user.petOwnerLocation : '',
      tk: user.petOwnerZipCode
    },
    onSubmit: values => {
        toggleChecked ? (
            UserUpdate( 
                localStorage.getItem("token"), 
                values.name, 
                values.email,  
                values.lat, 
                values.lng, 
                user.petOwnerImage, 
                values.location, 
                values.tk
            ).then(
                setEditMode(false)
            )
        ):(
            UserUpdate( 
                localStorage.getItem("token"), 
                values.name, 
                values.email,  
                0, 
                0, 
                user.petOwnerImage, 
                values.location, 
                values.tk
            ).then(
                setEditMode(false)
            )
        )
        
    },
  });


const [toggleChecked, setToggleChecked] = useState(user.petOwnerGPSLocationLat == 0 ? false : true)
const handleChangeToggle = (e) => {
    setToggleChecked(e.target.checked);
    if(!e.target.checked){
        formik1.setFieldValue('location', user.petOwnerLocation ? user.petOwnerLocation : '');
        formik1.setFieldValue('tk', user.petOwnerZipCode);
    }
}


const getAddressFromLatLng = (myLat ,myLng) => {
    fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + myLat + ',' + myLng + '&language=el&key=AIzaSyAk0UBca0lwoeowNItVUdBkISaFLAtfw6E')
    .then((response) => response.json())
    .then((responseJson) => {  
        // console.log('ADDRESS GEOCODE is BACK!! => ' + JSON.stringify((responseJson.results[0])))
        const locality = responseJson.results[0].address_components.find(local => local.types[0] === "locality").long_name;
        const zip_code = responseJson.results[0].address_components.find(addr => addr.types[0] === "postal_code").short_name;
        setAddress(locality);
        setTk(zip_code.replace(/\s/g, ""));
        formik1.setFieldValue('location', locality);
        formik1.setFieldValue('tk', zip_code.replace(/\s/g, ""));
    })
}


useEffect(() => {
    // console.log(userLocation)
    if(userLocation){
        getAddressFromLatLng(userLocation.lat, userLocation.lng)
        setLat(userLocation.lat);
        setLng(userLocation.lng);
        formik1.setFieldValue('lat', userLocation.lat);
        formik1.setFieldValue('lng', userLocation.lng);
    }
  }, [userLocation]);




  return (
    <>
    {!editMode ? (
        <div className='CardBlock'>
            <div className='seperator-s'></div>
            <h5 className='CardBlockTitle'>Προσωπικά στοιχεία</h5>
            <div className='seperator-xl'></div>
            <label className='Input-Label'>Ονοματεπώνυμο</label>
            <div className='text-input-container text-input-container-div'>
                <div className='text-input'>{user.petOwnerFullName}</div>
            </div>
            <div className='seperator-l'></div>
            <label className='Input-Label'>Διεύθυνση email</label>
            <div className='text-input-container text-input-container-div'>
                <div className='text-input'>{user.petOwnerEmail}</div>
            </div>
            <div className='seperator-l'></div>
            <label className='Input-Label'>Περιοχή διαμονής</label>
            <div className='text-input-container text-input-container-div'>
                <div className='text-input'>{user.petOwnerLocation}</div>
            </div>
            <div className='seperator-l'></div>
            <label className='Input-Label'>Ταχυδρομικός κώδικας</label>
            <div className='text-input-container text-input-container-div'>
                <div className='text-input'>{user.petOwnerZipCode}</div>
            </div>
            <div className='seperator-l'></div>
            <button type="button" className='button primary-button md-button outlined-button' onClick={()=> setEditMode(true)}>
            Επεξεργασία
            </button>
            <div className='seperator-s'></div>
        </div>
    ):(
        <form onSubmit={formik1.handleSubmit}>
        <div className='CardBlock'>
            <div className='seperator-s'></div>
            <h5 className='CardBlockTitle'>Προσωπικά στοιχεία</h5>
            <div className='seperator-xl'></div>
            <Input 
                label='Ονοματεπώνυμο' 
                formik={formik1} 
                type="text" 
                name="name" 
                value={formik1.values.name} 
                errors={formik1.errors.name}
                touched={formik1.touched.name}
            />
            <div className='seperator-l'></div>
            <Input 
                label='Διεύθυνση email' 
                formik={formik1} 
                type="text" 
                name="email" 
                value={formik1.values.email} 
                errors={formik1.errors.email}
                touched={formik1.touched.email}
            />
            <div className='seperator-l'></div>
            <label className='Input-Label'>Ενεργοποίηση υπηρεσιών τοποθεσίας</label>
            <Toggle
                    checked={toggleChecked}
                    text="Υπηρεσίες τοποθεσίας"
                    size='default'
                    onChange={handleChangeToggle}
                />
                <div className='seperator-l'></div>
            {
                !toggleChecked ? (
                    <>
                        <Input 
                            label='Περιοχή διαμονής' 
                            formik={formik1} 
                            type="text" 
                            name="location" 
                            value={formik1.values.location} 
                            errors={formik1.errors.location}
                            touched={formik1.touched.location}
                        />
                        <div className='seperator-l'></div>
                        <Input 
                            label='Ταχυδρομικός κώδικας' 
                            formik={formik1} 
                            type="text" 
                            name="tk" 
                            value={formik1.values.tk} 
                            errors={formik1.errors.tk}
                            touched={formik1.touched.tk}
                        />
                    </>
                ):(
                    <>
                    {user.petOwnerGPSLocationLat != 0 ? (
                            <GetGeolocation setUserLocation={setUserLocation} storedLat={user.petOwnerGPSLocationLat} storedLng={user.petOwnerGPSLocationLng} />
                        ):(
                            <GetGeolocation setUserLocation={setUserLocation} />
                        )
                    }
                        {tk ? (
                            <>
                                <div className='Info-Box' style={{marginTop:20}}>
                                    {/* <FiInfo /> */}
                                    <svg width="36px" height="36px" viewBox="0 0 36 36">
                                        <circle fill="#FFCB4C" cx="18" cy="17.018" r="17"></circle>
                                        <path fill="#65471B" d="M14.524 21.036a.914.914 0 0 1-.312-.464a.799.799 0 0 1 .59-1.021c4.528-1.021 7.577 1.363 7.706 1.465c.384.306.459.845.173 1.205c-.286.358-.828.401-1.211.097c-.11-.084-2.523-1.923-6.182-1.098a.91.91 0 0 1-.764-.184z"></path>
                                        <ellipse fill="#65471B" cx="13.119" cy="11.174" rx="2.125" ry="2.656"></ellipse>
                                        <ellipse fill="#65471B" cx="24.375" cy="12.236" rx="2.125" ry="2.656"></ellipse>
                                        <path fill="#F19020" d="M17.276 35.149s1.265-.411 1.429-1.352c.173-.972-.624-1.167-.624-1.167s1.041-.208 1.172-1.376c.123-1.101-.861-1.363-.861-1.363s.97-.4 1.016-1.539c.038-.959-.995-1.428-.995-1.428s5.038-1.221 5.556-1.341c.516-.12 1.32-.615 1.069-1.694c-.249-1.08-1.204-1.118-1.697-1.003c-.494.115-6.744 1.566-8.9 2.068l-1.439.334c-.54.127-.785-.11-.404-.512c.508-.536.833-1.129.946-2.113c.119-1.035-.232-2.313-.433-2.809c-.374-.921-1.005-1.649-1.734-1.899c-1.137-.39-1.945.321-1.542 1.561c.604 1.854.208 3.375-.833 4.293c-2.449 2.157-3.588 3.695-2.83 6.973c.828 3.575 4.377 5.876 7.952 5.048l3.152-.681z"></path>
                                        <path fill="#65471B" d="M9.296 6.351a.925.925 0 0 1-.391-.399a.8.8 0 0 1 .393-1.112c4.266-1.831 7.699-.043 7.843.034c.433.231.608.747.391 1.154c-.216.405-.74.546-1.173.318c-.123-.063-2.832-1.432-6.278.047a.915.915 0 0 1-.785-.042zm12.135 3.75a.924.924 0 0 1-.362-.424a.8.8 0 0 1 .468-1.084c4.381-1.536 7.685.48 7.823.567c.415.26.555.787.312 1.178c-.242.39-.776.495-1.191.238c-.12-.072-2.727-1.621-6.267-.379a.924.924 0 0 1-.783-.096z"></path>
                                    </svg>
                                    <div style={{display:'block'}}>
                                    {address && tk ? (<>Η περιοχή διαμονής σας είναι <strong>{address}</strong> και ο ταχυδρομικός σας κώδικας <strong>{tk}</strong>; </>):null}
                                    <br></br><u>Αν η τοποθεσία σας δεν εμφανίζεται σωστά, σύρετε την πινέζα στην σωστή θέση.</u></div>
                                </div>
                                <div className='seperator-xxl'></div>
                                {/* <button type="button" className='primary-button' onClick={()=>CompleteUpdate()}>Ολοκλήρωση εγγραφής</button> */}
                            </>
                            ):(<></>)
                        }
                           
                    </>
                )
            }
            
            <div className='seperator-l'></div>
            <div className='button-group'>
                <button type="button" className='button primary-button md-button' onClick={formik1.handleSubmit}>Αποθήκευση</button>
                <button type="button" className='button primary-button md-button outlined-button' onClick={()=> setEditMode(false)}>Άκυρο</button>
            </div>
            <div className='seperator-s'></div>
        </div>
        </form>
    )
    }
        
    </>
  );
}

export default PetOwnerPersonalInfoCard;