import React, { useRef, useEffect, useContext, useState } from 'react';
import { Outlet, Link } from "react-router-dom";


function HealthHeader() {

  return (
    <>
      <div className='CardBlock'>Health header</div>
    </>
  );
}

export default HealthHeader;