import React from "react";
import { useGeolocated } from "react-geolocated";
import GoogleMapComponent from "./GoogleMapComponent";

const GetGeolocation = ({setUserLocation, storedLat = null, storedLng = null}) => {

    
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
            },
            userDecisionTimeout: 5000,
            watchPosition: false,
            geolocationProvider: navigator.geolocation,
        });




    return !isGeolocationAvailable ? (
        <div>Your browser does not support Geolocation</div>
    ) : !isGeolocationEnabled ? (
        // <div>Geolocation is not enabled</div>
        <div>Οι υπηρεσίες τοποθεσίας δεν είναι ενεργοποιημένες</div>
    ) : storedLat && storedLng ? (
        <GoogleMapComponent setUserLoc={setUserLocation} storedLat={storedLat} storedLng={storedLng} />
    ) : coords ? (
        <GoogleMapComponent setUserLoc={setUserLocation} coords={coords} />
    ) : (
        // <div>Getting the location data&hellip; </div>
        <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <svg width="32" height="32" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" stroke="#6E9EF5">
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(1 1)" strokeWidth="2">
                        <circle strokeOpacity="0.3" cx="18" cy="18" r="18"/>
                        <path d="M36 18c0-9.94-8.06-18-18-18">
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                from="0 18 18"
                                to="360 18 18"
                                dur="1s"
                                repeatCount="indefinite"/>
                        </path>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default GetGeolocation;