import React, { useRef, useEffect, useContext, useState } from 'react';
import { Outlet, Link } from "react-router-dom";
import Calendar from './Calendar';
import HealthHeader from './HealthHeader';
// import './index.css';

function Health() {

  useEffect(() => {
    
  }, []);

  return (
    <>
      <div className="Main-Container-Outer"> 
        <div className="HealthPage Main-Container"> 
            <HealthHeader />
            <Calendar />
            <Outlet />
        </div>
      </div>
    </>
  );
}

export default Health;