import React from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";

function BackChevron() {
    
    const navigate = useNavigate();

    return (
        <FiChevronLeft style={{cursor:'pointer', marginRight:10}} size={30} onClick={() => navigate(-1)} />
    );
}

export default BackChevron;