// force the state to clear with fast refresh in Expo
// @refresh reset

import React, { useEffect, createContext, useState } from 'react';
import axios from 'axios';
import baseUrl from '../baseUrl';
import { useNavigate } from 'react-router-dom';

export const UserContext = createContext({});

export const UserContextProvider = props => {
  // Initial values are obtained from the props
  const navigate = useNavigate();
  const {
    user: initialUser,
    children
  } = props;

  // Use State to store the values
  const [user, setUser] = useState(initialUser);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [errorCallMsg, setErrorCallMsg] = useState(null)

  useEffect(() => {
    // user ? loginUser(email, pass) : null
    // localStorage.getItem("user") ? setUser(localStorage.getItem("user")) : null
    // console.log(user)
    if(localStorage.getItem("token")){
        setUser(JSON.parse(localStorage.getItem("user")))
    }
  }, [] )



  const registerUser = ( name, email, pass, lat, lng, image, location, zip) => {
    setLoaderVisible(true);
    axios({
        method: 'post',
        url: `${baseUrl}api/PetOwner/Register`,
        headers:  {'Content-Type': 'multipart/form-data'},
        data: {
            PetOwnerFullName: name,
            Password: pass,
            PetOwnerEmail: email,
            PetOwnerGPSLocationLat: lat,
            PetOwnerGPSLocationLng: lng,
            PetOwnerImage: image, 
            PetOwnerLocation: location,
            PetOwnerZipCode: zip
          }
    })
    .then(function (response) {
        console.log(response);
        setLoaderVisible(false);
        navigate('/');
    })
    .catch(function (error) {
        console.log('error',error);
    });
  };



  const removeUser = (id) => {
    // return database.deleteUser(id)
  };



  const getUser = (email, token) => {
    setLoaderVisible(true);
    axios({
        method: 'get',
        url: `${baseUrl}api/PetOwner/GetByEmail?petOwnerEmail=${email}`,
        headers:  {'Authorization': `Bearer ${token}`}
    })
    .then(function (response) {
        console.log('response',response);
        localStorage.setItem("user", JSON.stringify(response.data.result));
        setUser(response.data.result);
        setLoaderVisible(false);
    })
    .catch(function (error) {
        console.log('error',error);
    });


  }





  const loginUser = (email, pass) =>  {
    setErrorCallMsg(null)
    setLoaderVisible(true);
    axios.post(`${baseUrl}api/Auth/Login`, {
        email: email,
        password: pass
    })
    .then(function (response) {
        localStorage.setItem("token", response.data.token);
        getUser(email, response.data.token);
        setLoaderVisible(false);
    })
    .catch(function (error) {
        console.log(error.response.data);
        setLoaderVisible(false);
        setErrorCallMsg('Η διεύθυνση email ή/και ο κωδικός δεν είναι σωστά');
    });
  }



  const logoutUser = () =>  {
    localStorage.setItem("token", "");
    localStorage.setItem("user", "");
    setUser(null);
    navigate('/');
  }



  const updateUser = ( token, name, email, lat, lng, image, location, zip) =>  {
    // return database.updateUser(setUser, id, name, email, pass, location)
    setLoaderVisible(true);
    axios({
        method: 'put',
        url: `${baseUrl}api/PetOwner/UpdateByEmail`,
        headers:  {'Authorization': `Bearer ${token}`},
        data: {
            petOwnerFullName: name,
            petOwnerEmail: email,
            petOwnerGPSLocationLat: lat,
            petOwnerGPSLocationLng: lng,
            petOwnerImage: image,
            petOwnerLocation: location,
            petOwnerZipCode: zip
          }
    })
    .then(function (response) {
        console.log(response);
        getUser(email, token);
        setLoaderVisible(false);
    })
    .catch(function (error) {
        console.log('error',error);
    });
  }




  // Make the context object:
  const userContext = {
    user,
    setUser,
    registerUser,
    removeUser,
    loginUser,
    logoutUser,
    updateUser,
    loaderVisible,
    setLoaderVisible,
    errorCallMsg,
    setErrorCallMsg
  };

  // pass the value in provider and return
  return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>;
};