import React, { useRef, useEffect, useContext, useState, useLayoutEffect } from 'react';
import { Outlet, Link } from "react-router-dom";
import { useFormik } from 'formik'
import * as yup from 'yup'
import { RiHeartPulseLine, RiStackLine, RiFileTextLine, RiBearSmileLine } from "react-icons/ri";

import ImgPicker from '../../components/Img-Picker';
import Input from '../../components/Input';
import SelectField from '../../components/Select';
import BackChevron from '../../components/Back-Chevron';
import SmallPetsAnimation from './AddPetFiles/SmallPetsAnimation';

import dogbreeds from '../../data/DogBreeds'
import catbreeds from '../../data/CatBreeds'
import genders from '../../data/Genders'
import dogblood from '../../data/DogBloodTypes'
import catblood from '../../data/CatBloodTypes'
import colors from '../../data/PetColors'
import sterilized from '../../data/Sterilized'
import DatePickerInput from '../../components/DatePicker';


function AddPet() {

  const ref = useRef(null);
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, []);

  const [confirmedShownImageProfile, setConfirmedShownImageProfile] = useState(null);
  const [confirmedShownImageBg, setConfirmedShownImageBg] = useState(null);
  const [coverProcessing, setCoverProcessing] = useState(false);






  const [coverImgCanvasHeight, setCoverImgCanvasHeight] = useState(300);
  useEffect(() => {
    if(window.innerWidth < 667){
      setCoverImgCanvasHeight(220)
    }
  }, [window.innerWidth]);


  const addPetValidationSchema = yup.object().shape({
    type: yup
        .number()
        .integer()
        .required('Η επιλογή είδους είναι υποχρεωτική'),
    name: yup
        .string()
        .required('Το όνομα είναι υποχρεωτικό'),

    breed: yup
        .array()
        .min(1, 'Επιλέξτε τουλάχιστον 1 φυλή')
        .of( 
          yup.number().required()
        )
        .required('Η φυλή είναι υποχρεωτική'),

    // breed: yup
    //     .string()
    //     .when('type',{
    //       is: (type) => type!=0,
    //       then: yup
    //         .string()
    //         .required('Η φυλή είναι υποχρεωτική'),
    //       otherwise: yup
    //         .string()
    //         .required('Επιλέξτε είδος'),
    //     }),
    gender: yup
        .number()
        .typeError('Το φύλο είναι υποχρεωτικό')
        .required('Το φύλο είναι υποχρεωτικό'),
    color: yup
        .array()
        .min(1, 'Επιλέξτε τουλάχιστον 1 χρώμα')
        .of( 
          yup.number().required()
        )
        .required('Το χρώμα είναι υποχρεωτικό'),
    birth: yup
        .number()
        .integer()
        .required('Η ημερομηνία γέννησης είναι υποχρεωτική'),
    comment: yup
        .string(),
    sterilized: yup
        .number()
        .typeError('Η επιλογή είναι υποχρεωτική')
        .required('Η επιλογή είναι υποχρεωτική'),
    weightkg: yup
        .string()
        .required('Το βάρος είναι υποχρεωτικό'),
    weightdate: yup
        .number()
        .integer()
        .required('Το βάρος είναι υποχρεωτικό'),
    bloodtype: yup
        .number()
        .typeError('Η ομάδα αίματος είναι υποχρεωτική')
        .required('Η ομάδα αίματος είναι υποχρεωτική'),
    allergies: yup
        .string(),
    id_number: yup
        .string(),
    microchip: yup
        .string(),
    special_char: yup
        .string()
  });

  const formik = useFormik({
    validationSchema: addPetValidationSchema,
    initialValues: {
      type: 0,
      name: '',
      breed: [],
      gender: [],
      color: [],
      birth: '',
      comment: '',
      sterilized: [],
      weightkg: '',
      weightdate: '',
      bloodtype: [],
      allergies: '',
      id_number: '',
      microchip: '',
      special_char: ''
    },
    onSubmit: values => {
      // console.log('fdfdfd')
      console.log(values)
    },
    
  });



  const typeRef = useRef()
  const basicRef = useRef()
  const healthRef = useRef()
  const additionalRef = useRef()

  const [activeStep, setActiveStep] = useState(null);
  const handleScroll = () => {
      const position = window.pageYOffset+250;
      if(position < typeRef.current.offsetTop){
        setActiveStep(null)
      }else if(position >= typeRef.current.offsetTop && position < basicRef.current.offsetTop){
        setActiveStep(0)
      }else if(position >= basicRef.current.offsetTop && position < healthRef.current.offsetTop-300){
        setActiveStep(1)
      }else if(position >= healthRef.current.offsetTop-300 && position < additionalRef.current.offsetTop-300){
        setActiveStep(2)
      }else if(position >= additionalRef.current.offsetTop-300){
        setActiveStep(3)
      }
  };
  
  useEffect(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);



  const GoToStep = (x) => {
    if(x === 0){
      window.scrollTo(0, typeRef.current.offsetTop-250);
    }
    if(x === 1){
      window.scrollTo(0, basicRef.current.offsetTop-250);
    }
    if(x === 2){
      window.scrollTo(0, healthRef.current.offsetTop-250);
    }
    if(x === 3){
      window.scrollTo(0, additionalRef.current.offsetTop-250);
    }
  }


  const [animalType, setAnimalType] = useState(0)
  const ChooseType = (x) => {
    formik.setFieldValue("type", x)
    setAnimalType(x)
    // console.log(x)
  }


  const [animalBreed, setAnimalBreed] = useState(null)
  const [animalBlood, setAnimalBlood] = useState(null)
  
  useEffect(() => {
    
    if(formik.values.type === 1){
      setAnimalBreed(dogbreeds)
      setAnimalBlood(dogblood)
    }else if(formik.values.type === 2){
      setAnimalBreed(catbreeds)
      setAnimalBlood(catblood)
    }else{
      setAnimalBreed(null)
      setAnimalBlood(null)
    }
    
    
}, [formik.values.type]);
  


// useEffect(() => {
// console.log(formik.values.breed)
    
// }, [formik.values.breed]);

  const options = [
    { value: '0', label: 'Yorky' },
    { value: '1', label: 'Kanis' },
    { value: '2', label: 'pitbull' },
    { value: '3', label: 'husky' },
    { value: '4', label: 'bobtail' },
    { value: '5', label: 'kokoni' }
  ]


  return (
    <>
      <div className="Main-Container-Outer"> 
        <div className="AddPetPage Main-Container"> 
          <div className='CardBlock CardBlock-Nav'>
            <h5 className="CardBlockTitleMajor CardBlockTitle-With-Back">
              <BackChevron />
              <div style={{display:'flex',justifyContent:'center', alignItems:'center'}}>Προσθήκη κατοικίδιου <div style={{position:'relative', width:22, height:22}}>
                <SmallPetsAnimation />
                </div>
              </div>
              <div style={{width:30}}></div>
            </h5>
          </div>
          <div className='CardBlock CardBlock-Inset'>
            <div className='Top-Pet-Section'>
              <div className='Pet-Cover-Image' ref={ref}>
                <ImgPicker 
                  confirmedShownImage={confirmedShownImageBg} 
                  setConfirmedShownImage={setConfirmedShownImageBg} 
                  width={width}
                  height={coverImgCanvasHeight}
                  borderRadius={0}
                  setCoverProcessing={setCoverProcessing}
                  isCover={true}
                />
              </div>
              <div className='Pet-Profile-Image' style={{display: coverProcessing ? 'none' : 'block'}}>
                <ImgPicker 
                  confirmedShownImage={confirmedShownImageProfile} 
                  setConfirmedShownImage={setConfirmedShownImageProfile} 
                  width={130}
                  height={130}
                  borderRadius={130}
                  isPet={true}
                />
              </div>
            </div>
          </div>


          <div className='CardBlocks-Cols-Container'>
            <div className='CardBlocks-Col-s'>
              <div className='Add-Pet-Steps'>
                <div className='CardBlock'>
                  <Link to={'#'} className={activeStep == 0 ? 'Add-Pet-Step active' : 'Add-Pet-Step'} onClick={()=>GoToStep(0)}><RiBearSmileLine /> Επιλογή είδους</Link>
                  <Link to={'#'} className={activeStep == 1 ? 'Add-Pet-Step active' : 'Add-Pet-Step'} onClick={()=>GoToStep(1)}><RiStackLine/> Βασικά στοιχεία</Link>
                  <Link to={'#'} className={activeStep == 2 ? 'Add-Pet-Step active' : 'Add-Pet-Step'} onClick={()=>GoToStep(2)}><RiHeartPulseLine /> Υγεία</Link>
                  <Link to={'#'} className={activeStep == 3 ? 'Add-Pet-Step active' : 'Add-Pet-Step'} onClick={()=>GoToStep(3)}><RiFileTextLine /> Πρόσθετες πληροφορίες</Link>
                </div>
              </div>
            </div>
            <div className='CardBlocks-Col-l'>


            <form onSubmit={formik.handleSubmit}>

              <div className='CardBlock' ref={typeRef}>
                <div className="seperator-s"></div>
                <h5 className="CardBlockTitle">Επιλέξτε είδος</h5>
                <div className="seperator-xl"></div>
                <div className='button-group '>
                  <button 
                    onClick={() => ChooseType(1)} 
                    type='button'
                    className={formik.values.type === 1 ? 'button primary-button md-button outlined-button selected' : 'button primary-button md-button outlined-button'}
                  >Σκύλος</button>
                  <button 
                    onClick={() => ChooseType(2)}
                    type='button' 
                    className={formik.values.type === 2 ? 'button primary-button md-button outlined-button selected' : 'button primary-button md-button outlined-button'}
                  >Γάτα</button>
                </div>
                <div className="seperator-s"></div>
              </div>



              <div className='CardBlock' ref={basicRef}>
                <div className="seperator-s"></div>
                <h5 className="CardBlockTitle">Βασικά στοιχεία</h5>
                <div className="seperator-xl"></div>
                <Input 
                  label='Όνομα' 
                  formik={formik} 
                  type="text" 
                  name="name" 
                  value={formik.values.name} 
                  errors={formik.errors.name}
                  touched={formik.touched.name}
                />
                <div className="seperator-l"></div>
                <SelectField
                  isDependent={true}
                  animalType={animalType}
                  isDisabled={formik.values.type === 0 ? true : false}
                  label='Φυλή' 
                  placeholder='Επιλέξτε φυλή'
                  formik={formik} 
                  data={animalBreed}
                  isMulti={true}
                  closeOnBlur={false}
                  name="breed" 
                  value={formik.values.breed} 
                  errors={formik.errors.breed}
                  touched={formik.touched.breed}
                />
                <div className="seperator-l"></div>
                <SelectField
                  label='Φύλο' 
                  placeholder='Επιλέξτε φύλο'
                  formik={formik} 
                  data={genders}
                  isMulti={false}
                  closeOnBlur={true}
                  name="gender" 
                  value={formik.values.gender} 
                  errors={formik.errors.gender}
                  touched={formik.touched.gender}
                />
                <div className="seperator-l"></div>
                <SelectField
                  label='Χρώμα' 
                  placeholder='Επιλέξτε χρώμα'
                  formik={formik} 
                  data={colors}
                  isMulti={true}
                  closeOnBlur={false}
                  name="color" 
                  value={formik.values.color} 
                  errors={formik.errors.color}
                  touched={formik.touched.color}
                />
                <div className="seperator-l"></div>
                <DatePickerInput
                  label='Ημερομηνία γέννησης' 
                  name="birth" 
                  formik={formik} 
                  value={formik.values.birth} 
                  errors={formik.errors.birth}
                  touched={formik.touched.birth}
                />
                <div className="seperator-l"></div>
                <Input 
                  label='Γράψτε λίγα λόγια (προαιρετικό)' 
                  formik={formik} 
                  type="text" 
                  name="comment" 
                  value={formik.values.comment} 
                  errors={formik.errors.comment}
                  touched={formik.touched.comment}
                />
                <div className="seperator-s"></div>
              </div>



              <div className='CardBlock' ref={healthRef}>
                <div className="seperator-s"></div>
                <h5 className="CardBlockTitle">Υγεία</h5>
                <div className="seperator-xl"></div>
                <SelectField
                  label='Στείρωση' 
                  // loadedContent={sterilized[0].value}
                  placeholder='Επιλέξτε αν το κατοικίδιο είναι στειρωμένο'
                  formik={formik} 
                  data={sterilized}
                  isMulti={false}
                  closeOnBlur={true}
                  name="sterilized" 
                  value={formik.values.sterilized} 
                  errors={formik.errors.sterilized}
                  touched={formik.touched.sterilized}
                />
                <div className="seperator-l"></div>
                <Input 
                  label='Βάρος' 
                  formik={formik} 
                  type="text" 
                  name="weightkg" 
                  value={formik.values.weightkg} 
                  errors={formik.errors.weightkg}
                  touched={formik.touched.weightkg}
                />
                <div className="seperator-l"></div>
                <DatePickerInput
                  label='Ημερομηνία μέτρησης' 
                  name="weightdate" 
                  formik={formik} 
                  value={formik.values.weightdate} 
                  errors={formik.errors.weightdate}
                  touched={formik.touched.weightdate}
                />
                <div className="seperator-l"></div>
                <SelectField
                  // loadedContent={animalBlood ? animalBlood[0].value : null}
                  isDependent={true}
                  animalType={animalType}
                  isDisabled={formik.values.type === 0 ? true : false}
                  label='Ομάδα αίματος' 
                  placeholder='Επιλέξτε την ομάδα αίματος του κατοικίδιου'
                  formik={formik} 
                  data={animalBlood}
                  isMulti={false}
                  closeOnBlur={true}
                  name="bloodtype" 
                  value={formik.values.bloodtype} 
                  errors={formik.errors.bloodtype}
                  touched={formik.touched.bloodtype}
                />
                <div className="seperator-l"></div>
                <Input 
                  label='Αλλεργίες (προαιρετικό)' 
                  formik={formik} 
                  type="text" 
                  name="allergies" 
                  value={formik.values.allergies} 
                  errors={formik.errors.allergies}
                  touched={formik.touched.allergies}
                />
                <div className="seperator-s"></div>
              </div>



              <div className='CardBlock' ref={additionalRef}>
                <div className="seperator-s"></div>
                <h5 className="CardBlockTitle">Πρόσθετες πληροφορίες</h5>
                <div className="seperator-xl"></div>
                <Input 
                  label='Αριθμός ταυτότητας (προαιρετικό)' 
                  formik={formik} 
                  type="text" 
                  name="id_number" 
                  value={formik.values.id_number} 
                  errors={formik.errors.id_number}
                  touched={formik.touched.id_number}
                />
                <div className="seperator-l"></div>
                <Input 
                  label='Κωδικός microchip (προαιρετικό)' 
                  formik={formik} 
                  type="text" 
                  name="microchip" 
                  value={formik.values.microchip} 
                  errors={formik.errors.microchip}
                  touched={formik.touched.microchip}
                />
                <div className="seperator-l"></div>
                <Input 
                  label='Ιδιαίτερα χαρακτηριστικά (προαιρετικό)' 
                  formik={formik} 
                  type="text" 
                  name="special_char" 
                  value={formik.values.special_char} 
                  errors={formik.errors.special_char}
                  touched={formik.touched.special_char}
                />
                <div className="seperator-s"></div>
              </div>



              <button type="button" className="button primary-button" onClick={formik.handleSubmit}>Προσθήκη</button>

            </form>

            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default AddPet;