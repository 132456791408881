import React, {useEffect, useState, useContext } from 'react';
import { Outlet, Link } from "react-router-dom";
import { useFormik } from 'formik'
import * as yup from 'yup'
import { FiArrowLeft, FiMapPin } from "react-icons/fi";
import { Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import Toggle from '../../components/Toggle';
import Input from '../../components/Input';
import Password from '../../components/Password';
import GetGeolocation from '../../components/GetGeolocation';
import ImgPicker from '../../components/Img-Picker'

import { UserContext } from '../../context/UserContext';

// import './index.css';

function Register() {

    const { registerUser } = useContext(UserContext);

  const registerValidationSchema = yup.object().shape({
    name: yup
        .string()
        .required('Το ονοματεπώνυμο είναι υποχρεωτικό'),
    email: yup
        .string()
        .email('Παρακαλώ εισάγετε διεύθυνση email')
        .required('Η διεύθυνση email είναι υποχρεωτική'),
    password: yup
        .string()
        .matches(/\w*[a-z]\w*/,  'Ο κωδικός πρέπει να έχει τουλάχιστον ένα πεζό γράμμα')
        .matches(/\w*[A-Z]\w*/,  'Ο κωδικός πρέπει να έχει τουλάχιστον ένα κεφαλαίο γράμμα')
        .matches(/\d/, 'Ο κωδικός πρέπει να έχει τουλάχιστον έναν αριθμό')
        .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, 'Ο κωδικός πρέπει να έχει τουλάχιστον έναν ειδικό χαρακτήρα')
        .min(8, ({ min }) => 'Ο κωδικός πρέπει να αποτελείται από τουλάχιστον 8 χαρακτήρες')
        .required('Ο κωδικός είναι υποχρεωτικός'),
    repassword: yup
        .string()
        .oneOf([yup.ref('password')], 'Οι κωδικοί δεν ταιριάζουν')
        .required('Η επαλύθευση κωδικού είναι υποχρεωτική'),
  })
  const registerValidationSchema2 = yup.object().shape({
    tk: yup
        .string()
        .matches(/^[0-9]+$/, "Ο ταχυδρομικός κώδικας αποτελείται μόνο από αριθμούς")
        .min(5, ({ min }) => 'Ο ταχυδρομικός κώδικας πρέπει να αποτελείται από 5 ψηφία')
        .max(5, ({ max }) => 'Ο ταχυδρομικός κώδικας πρέπει να αποτελείται από 5 ψηφία')
        .required('Ο ταχυδρομικός κώδικας είναι υποχρεωτικός'),
    })


  const [swiper, setSwiper] = useState();

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [pass, setPass] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [confirmedShownImage, setConfirmedShownImage] = useState(null)

  const [userLocation, setUserLocation] = useState(null);
  const [address, setAddress] = useState(null)
  const [tk, setTk] = useState(null)


  
  const formik1 = useFormik({
    validationSchema: registerValidationSchema,
    initialValues: {
      name: '',
      email: '',
      password: '',
      repassword: ''
    },
    onSubmit: values => {
        swiper.slideNext()
        setName(values.name);
        setEmail(values.email);
        setPass(values.password);
    },
  });

  const formik2 = useFormik({
    validationSchema: registerValidationSchema2,
    initialValues: {
      tk: ''
    },
    onSubmit: values => {
        registerUser(name, email, pass, lat, lng, confirmedShownImage, address, values.tk)

    },
  });


  const [loadedRegisterPage, setLoadedRegisterPage] = useState(false)

  useEffect(() => {
    setLoadedRegisterPage(true)
  }, []);






const [toggleChecked, setToggleChecked] = useState(false)
const handleChangeToggle = (e) => {
    setToggleChecked(e.target.checked);
    if(e.target.checked){
        
    }
}



const getAddressFromLatLng = (myLat ,myLng) => {
    fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + myLat + ',' + myLng + '&language=el&key=AIzaSyAk0UBca0lwoeowNItVUdBkISaFLAtfw6E')
    .then((response) => response.json())
    .then((responseJson) => {  
        // console.log('ADDRESS GEOCODE is BACK!! => ' + JSON.stringify((responseJson.results[0])))
        const locality = responseJson.results[0].address_components.find(local => local.types[0] === "locality").long_name;
        const zip_code = responseJson.results[0].address_components.find(addr => addr.types[0] === "postal_code").short_name;
        setAddress(locality);
        setTk(zip_code.replace(/\s/g, ""))
    })
}


useEffect(() => {
    console.log(userLocation)
    if(userLocation){
        getAddressFromLatLng(userLocation.lat, userLocation.lng)
        setLat(userLocation.lat);
        setLng(userLocation.lng)
    }
  }, [userLocation]);



const CompleteRegister = () => {
    registerUser(name, email, pass, lat, lng, confirmedShownImage, address, tk);
    // console.log('register completed')
}



  return (
    <div className={loadedRegisterPage ? "Register-Container-Outer active" : "Register-Container-Outer"}>
      <div className="Register-Container">
        
        <div className={loadedRegisterPage ? "Register-Box active" : "Register-Box"}>
          <div>
            <div className="Header-Register">
                <Link to="/">
                    <div className={loadedRegisterPage ? "Logo-Top active" : "Logo-Top"}>
                    <svg width="120" viewBox="0 0 218.6 86.6">
                        <path className="st0" fill="#7474A3" d="M0,1.7h16.7v66H0V1.7z"/>
                        <path className="st1" fill="#54B754" d="M160.8,33.2h-10.9v34.6h-16.7V33.2h-8l0-13.1h8.3v-4.6C133.4,5.7,138.7,0,149,0h11.7v12.8h-7.4
                        c-2.5,0-4,1.5-4,3.9v3.3h11.4V33.2z"/>
                        <path className="st2" fill="#FF6666" d="M218.6,20.1l-20.3,46.4c-6.5,14.8-12.6,20.2-25.1,20.2h-5.7V72.3h4.6c6.4,0,8.3-1.7,10.6-7.7l-20-44.5h18.2
                        l10.3,26.7l9.6-26.7H218.6z"/>
                        <circle className="st3" fill="#6E9EF5" cx="45.8" cy="43.5" r="25.8"/>
                        <circle className="st4" fill="#FFCC33" cx="99.3" cy="43.5" r="25.8"/>
                    </svg>
                    </div>
                </Link>
                <Link to="/" className='button primary-button sm-button outlined-button'>
                    Σύνδεση
                </Link>
            </div>

            <Swiper
                onSwiper={(swiper) => setSwiper(swiper)}
                speed={500}
                effect="cube"
                className='Register-Swiper'
                spaceBetween={50}
                slidesPerView={1}
                noSwiping={true}
                allowTouchMove={false}
            >
            <SwiperSlide>

            <div className="Register-First-Step">
                <h3 className="Title" style={{marginBottom:5}}>Εγγραφή</h3>
                <p className="SubTitle">Δημιουργήστε τον λογαριασμό σας &#128525;</p>
                <div className='seperator-xxxl'></div>


                <ImgPicker 
                    confirmedShownImage={confirmedShownImage} 
                    setConfirmedShownImage={setConfirmedShownImage} 
                    width={130}
                    height={130}
                    borderRadius={130}
                />
            

                <form onSubmit={formik1.handleSubmit}>
                <div className='seperator-xl'></div>
                <Input 
                    label='Ονοματεπώνυμο' 
                    formik={formik1} 
                    placeholder="Γιώργος Γεωργίου" 
                    type="text" 
                    name="name" 
                    value={formik1.values.name} 
                    errors={formik1.errors.name}
                    touched={formik1.touched.name}
                />
                <div className='seperator-l'></div>
                <Input 
                    label='Διεύθυνση email' 
                    formik={formik1} 
                    placeholder="name@email.com" 
                    type="email" 
                    name="email" 
                    value={formik1.values.email} 
                    errors={formik1.errors.email}
                    touched={formik1.touched.email}
                />
                <div className='seperator-l'></div>
                <Password 
                    label='Κωδικός'
                    formik={formik1}
                    name='password'
                    value={formik1.values.password}
                    errors={formik1.errors.password}
                    touched={formik1.touched.password}
                />
                <div className='seperator-l'></div>
                <Password 
                    label='Επαλύθευση κωδικού'
                    formik={formik1}
                    name='repassword'
                    value={formik1.values.repassword}
                    errors={formik1.errors.repassword}
                    touched={formik1.touched.repassword}
                />
                <div className='seperator-xxl'></div>

                <button type="button" className='button primary-button' onClick={formik1.handleSubmit}>Επόμενο</button>

    
                </form>
            </div>


            </SwiperSlide>

            <SwiperSlide>
            <div className="Register-Second-Step">
                <h3 className="Title Title-With-Back" style={{marginBottom:5}}><FiArrowLeft style={{cursor:'pointer'}} onClick={()=> swiper.slidePrev()} />Τοποθεσία<div className='empty'></div></h3>
                <p className="SubTitle">Βοηθήστε μας να σας παρέχουμε σημαντικές ειδοποιήσεις βάσει της τοποθεσίας σας<br></br><span className='lighter'>(φόλες, κακοποίηση ή εξαφάνιση ζώου κλπ)</span></p>
                <h4 className='Sm-Title' style={{display:'flex', alignItems:'center', justifyContent:'center'}}>Ενεργοποιήστε τις υπηρεσίες τοποθεσίας <FiMapPin style={{marginLeft:8}} /></h4>
                <Toggle
                    checked={toggleChecked}
                    text="Υπηρεσίες τοποθεσίας"
                    size='default'
                    onChange={handleChangeToggle}
                />
                <div className='seperator-xl'></div>
                { 
                    toggleChecked ? (
                        <>
                            <GetGeolocation setUserLocation={setUserLocation} />
                            {tk ? (
                                <>
                                    <div className='Info-Box' style={{marginTop:20}}>
                                        {/* <FiInfo /> */}
                                        <svg width="36px" height="36px" viewBox="0 0 36 36">
                                            <circle fill="#FFCB4C" cx="18" cy="17.018" r="17"></circle>
                                            <path fill="#65471B" d="M14.524 21.036a.914.914 0 0 1-.312-.464a.799.799 0 0 1 .59-1.021c4.528-1.021 7.577 1.363 7.706 1.465c.384.306.459.845.173 1.205c-.286.358-.828.401-1.211.097c-.11-.084-2.523-1.923-6.182-1.098a.91.91 0 0 1-.764-.184z"></path>
                                            <ellipse fill="#65471B" cx="13.119" cy="11.174" rx="2.125" ry="2.656"></ellipse>
                                            <ellipse fill="#65471B" cx="24.375" cy="12.236" rx="2.125" ry="2.656"></ellipse>
                                            <path fill="#F19020" d="M17.276 35.149s1.265-.411 1.429-1.352c.173-.972-.624-1.167-.624-1.167s1.041-.208 1.172-1.376c.123-1.101-.861-1.363-.861-1.363s.97-.4 1.016-1.539c.038-.959-.995-1.428-.995-1.428s5.038-1.221 5.556-1.341c.516-.12 1.32-.615 1.069-1.694c-.249-1.08-1.204-1.118-1.697-1.003c-.494.115-6.744 1.566-8.9 2.068l-1.439.334c-.54.127-.785-.11-.404-.512c.508-.536.833-1.129.946-2.113c.119-1.035-.232-2.313-.433-2.809c-.374-.921-1.005-1.649-1.734-1.899c-1.137-.39-1.945.321-1.542 1.561c.604 1.854.208 3.375-.833 4.293c-2.449 2.157-3.588 3.695-2.83 6.973c.828 3.575 4.377 5.876 7.952 5.048l3.152-.681z"></path>
                                            <path fill="#65471B" d="M9.296 6.351a.925.925 0 0 1-.391-.399a.8.8 0 0 1 .393-1.112c4.266-1.831 7.699-.043 7.843.034c.433.231.608.747.391 1.154c-.216.405-.74.546-1.173.318c-.123-.063-2.832-1.432-6.278.047a.915.915 0 0 1-.785-.042zm12.135 3.75a.924.924 0 0 1-.362-.424a.8.8 0 0 1 .468-1.084c4.381-1.536 7.685.48 7.823.567c.415.26.555.787.312 1.178c-.242.39-.776.495-1.191.238c-.12-.072-2.727-1.621-6.267-.379a.924.924 0 0 1-.783-.096z"></path>
                                        </svg>
                                        <div style={{display:'block'}}>
                                        {address && tk ? (<>Η περιοχή διαμονής σας είναι <strong>{address}</strong> και ο ταχυδρομικός σας κώδικας <strong>{tk}</strong>; </>):null}
                                        <br></br><u>Αν η τοποθεσία σας δεν εμφανίζεται σωστά, σύρετε την πινέζα στην σωστή θέση.</u></div>
                                    </div>
                                    {/* <div className='seperator-xl'></div>
                                    <label className='Input-Label'>Ταχυδρομικός κώδικας</label>
                                    <div className={'text-input-container'}>
                                        <input className='text-input'
                                            disabled={true}
                                            name="tk"
                                            type="text"
                                            placeholder="12345"
                                            style={{
                                                paddingLeft:18,
                                                paddingRight:18
                                            }}
                                            onChange={formik2.handleChange}
                                            onBlur={formik2.handleBlur}
                                            value={tk}
                                        />
                                    </div> */}
                                    <div className='seperator-xxl'></div>
                                    <button type="button" className='button primary-button' onClick={()=>CompleteRegister()}>Ολοκλήρωση εγγραφής</button>
                                </>
                                ):(<></>)
                            }
                           
                        </>
                    ):(
                        <>
                        <h4 className='Sm-Title Sm-Title-With-Arrow'>
                            <span>ή απλά εισάγετε τον ταχυδρομικό κώδικα<br></br>της περιοχής που διαμένετε</span>
                            <svg viewBox="0 0 22 66.2" fill='none' strokeWidth={2} >
                                <g>
                                    <path d="M2.8,7.7c16,12,27,28.7,1.8,52.6 M0.8,52.8c1.4,3.1,0.6,9.5,0,12.7c4.1-1.1,6.7-4.5,11-4.8"/>
                                </g>
                            </svg>

                        </h4>
                        <form onSubmit={formik2.handleSubmit}>
                            <div className='seperator-xl'></div>
                            <Input 
                                label='Ταχυδρομικός κώδικας' 
                                formik={formik2} 
                                placeholder="12345" 
                                type="text" 
                                name="tk" 
                                value={formik2.values.tk} 
                                errors={formik2.errors.tk}
                                touched={formik2.touched.tk}
                            />
                            <div className='seperator-xxl'></div>
            
                            <button type="button" className='button primary-button' onClick={formik2.handleSubmit}>Ολοκλήρωση εγγραφής</button>
                        </form>
                        </>
                    )
                }

                
            </div>
            </SwiperSlide>
            </Swiper>

          </div>

          <div className='Footer-Bottom'>
            <div className='Bottom-Links'>
              <span>© 2022 loofy</span> · <Link to="#">Όροι χρήσης</Link> · <Link to="#">Πολιτική προστασίας</Link>
            </div>
          </div>

        </div>
      </div>

{/* 
      <div className={loadedRegisterPage ? 'Poster-Container active' : 'Poster-Container' }>
        <Lottie className={loadedRegisterPage ? 'LottieLogin active' : 'LottieLogin'} animationData={lot} loop={true} />
        <div className={loadedRegisterPage ? "bubbles-container active" : "bubbles-container"}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={loadedRegisterPage ? 'Welcome-Text active' : 'Welcome-Text'}>

          <svg className="login-logo" width="180" viewBox="0 0 218.6 86.6">
              <path className="st0" fill="#7474A3" d="M0,1.7h16.7v66H0V1.7z"/>
              <path className="st1" fill="#54B754" d="M160.8,33.2h-10.9v34.6h-16.7V33.2h-8l0-13.1h8.3v-4.6C133.4,5.7,138.7,0,149,0h11.7v12.8h-7.4
              c-2.5,0-4,1.5-4,3.9v3.3h11.4V33.2z"/>
              <path className="st2" fill="#FF6666" d="M218.6,20.1l-20.3,46.4c-6.5,14.8-12.6,20.2-25.1,20.2h-5.7V72.3h4.6c6.4,0,8.3-1.7,10.6-7.7l-20-44.5h18.2
              l10.3,26.7l9.6-26.7H218.6z"/>
              <circle className="st3" fill="#6E9EF5" cx="45.8" cy="43.5" r="25.8"/>
              <circle className="st4" fill="#FFCC33" cx="99.3" cy="43.5" r="25.8"/>
          </svg> 

          <h1>Καλωσήρθατε στο loofy!</h1>
          <h2>Την πληρέστερη πλατφόρμα για τα κατοικίδια σας!</h2>
          <div className={loadedRegisterPage ? 'Login-Swiper active' : 'Login-Swiper'}>
            <Swiper
              speed={1000}
              modules={[Autoplay]}
              autoHeight={true}
              direction={"vertical"}
              spaceBetween={50}
              slidesPerView={1}
              // pagination={{ clickable: true }}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
              autoplay={{
                disableOnInteraction: false, // Optional, but recommended
                delay: 3000,
                pauseOnMouseEnter: true,
              }}
              loop={true}
            >
              <SwiperSlide><div className='Slide-Content'><div><img src={healthcard} alt="healthcard"/></div>Με το ηλεκτρονικό βιβλιάριο υγείας μένετε πάντα ενημερωμένοι για την υγεία του κατοικίδιου σας</div></SwiperSlide>
              <SwiperSlide><div className='Slide-Content'><div><img src={bell} alt="bell" /></div>Χάθηκε; Βρέθηκε; Εντοπίστηκε φόλα; Ειδοποιήστε και ειδοποιηθείτε για οτιδήποτε συμβεί στην περιοχή σας!</div></SwiperSlide>
              <SwiperSlide><div className='Slide-Content'><div><img src={videocall} alt="videocall" /></div>Βρείτε τον ιδανικό εκπαιδευτή για το ζωάκι σας και κλείστε άμεσα online συνεδρίες, όπου και αν βρίσκεστε!</div></SwiperSlide>
              <SwiperSlide><div className='Slide-Content'><div><img src={marketplace} alt="marketplace" /></div>Το μεγαλύτερο marketplace για τους μικρούς μας φίλους με εξατομικευμένα προϊόντα και υπηρεσίες</div></SwiperSlide>
              <SwiperSlide><div className='Slide-Content'><div><img src={hangout} alt="hangout" /></div>Δημιουργήστε εκδηλώσεις, βόλτες και γνωρίστε απίθανους φίλους και εκπληκτικά μέρη για το κατοικίδιο σας</div></SwiperSlide>
              <SwiperSlide><div className='Slide-Content'><div><img src={adopt} alt="adopt" /></div>Υιοθετήστε ζώα συντροφιάς, αναζητήστε και βρείτε εύκολα το νέο μέλος της οικογένειας σας</div></SwiperSlide>
            </Swiper>
          </div>

        </div>
      </div> */}

      <Outlet />
    </div>
  );
}

export default Register;