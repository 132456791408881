import React, {useContext} from 'react';
import { BrowserRouter, Routes, Route, Navigate  } from "react-router-dom";
import { UserContext } from '../context/UserContext';
import App from '../App';
import Login from '../pages/Login';
import Register from '../pages/Register';
import Loader from '../components/Loader';






const AuthNavigator = () => {
    const { user, loaderVisible } = useContext(UserContext);

    if(!user && !localStorage.getItem("token")){
        return (<>
                    <Routes>
                        <Route index element={<Login />}/>
                        <Route path="register" element={<Register />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                    {
                        loaderVisible ? <Loader type='external' /> :null
                    }
                    
                </>
        )
    }else{
        return(
            <>
                <App />
                {
                    loaderVisible ? <Loader type='external' /> :null
                }
            </>
        )
    }
}



export default AuthNavigator;