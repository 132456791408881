import React, {useState, useEffect} from 'react';
import { Outlet, Link, NavLink } from "react-router-dom";
import { IoAdd } from "react-icons/io5";

const data = [
  {id:1, name:'Luther', img:'https://static.scientificamerican.com/blogs/cache/file/BB6F1FE0-4FDE-4E6E-A986664CE30602E4_source.jpg?w=590&h=800&2F8476C1-DF14-49BA-84FFE94218CC4933'},
  {id:2, name:'Miley Cirus', img:'https://st2.depositphotos.com/2166845/5890/i/450/depositphotos_58906929-stock-photo-cairn-terrier-puppy.jpg'},
  {id:3, name:'Robinhood', img:'https://media-cldnry.s-nbcnews.com/image/upload/t_fit-760w,f_auto,q_auto:best/streams/2013/May/130522/6C7536084-g-hlt-120105-puppy-423p.jpg'},
  {id:4, name:'Lilly', img:'https://thumbor.forbes.com/thumbor/fit-in/900x510/https://www.forbes.com/advisor/wp-content/uploads/2022/08/dog-wearing-tutu.jpeg.jpg'},
  // {name:'', img:''},
  // {name:'', img:''},
  // {name:'', img:''},
  // {name:'', img:''},
  // {name:'', img:''},
  // {name:'', img:''},
  // {name:'', img:''},
  // {name:'', img:''},
  // {name:'', img:''},
]

function PetOwnerPetsCard({user}) {

//   const [ visiblePass, setVisiblePass ] = useState(false);
const [pets, setPets] = useState(null);

useEffect(() => {
  setPets(data)
}, [data]);

  return (
    <>
        <div className='CardBlock'>
            <div className='seperator-s'></div>
            <h5 className='CardBlockTitle Pets-Title-Container Card-Title-With-Link'>
              <span className='Pets-Title-With-Num'>Κατοικίδια {pets ? pets.length > 1 ? (<span className='PetsNum-Title'>{pets.length}</span>) : null : null}</span>
              {pets ? pets.length > 2 ? (
                  <Link className='CardBlock-Link-View-All' to="/">Όλα τα κατοικίδια</Link>
                ):null:null
              }
            </h5>
            {/* <img src={EmptyPets} style={{maxWidth:300, marginLeft:'auto', marginRight:'auto', display:'block', opacity:0.7}} /> */}
            {
              pets ? (
                <div className='Pet-Row-Block'>
                  {data.map((item) =>  
                   <Link key={item.id} className='Pet-Row-Profile-Photo-Block' to="/">
                      <div className='Pet-Row-Profile-Photo'>
                        <img src={item.img} />
                      </div>
                      <h4>{item.name}</h4>
                    </Link>
                  )
                 }
                  <Link className='Pet-Row-Profile-Photo-Add-Block' to="/add-pet">
                    <div className='Circular-Button Pet-Row-Profile-Photo-Add'>
                      <IoAdd size={36} />
                    </div>
                  </Link>
                </div>
              ):(
                <>
                  <div className='seperator-l'></div>
                  <h4 className='Empty-Text' style={{textAlign:'center'}}>Δεν υπάρχουν κατοικίδια</h4>
                  <div className='seperator-xxl'></div>
                  <Link className='button primary-button md-button outlined-button' to='/add-pet'>
                    <IoAdd size={24} /> Προσθέστε κατοικίδιο
                  </Link>
                </>
              )
              
            }
            <div className='seperator-s'></div>
        </div>
    </>
  );
}

export default PetOwnerPetsCard;