import React, {useState} from 'react';
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Outlet, Link } from "react-router-dom";

function Password({label, formik, name, value, errors, touched, forgotpassword = false, hasErrorOutside = null}) {

  const [ visiblePass, setVisiblePass ] = useState(false);

  return (
    <>
        <div className='Double-Label'>
            <label className='Input-Label'>{label}</label>
            {forgotpassword ? <Link className='Input-Label lighter' to="/register">Ξέχασα τον κωδικό μου</Link> : null}
        </div>
        <div className={(errors && touched || hasErrorOutside) ? 'text-input-container text-input-error': 'text-input-container'}>
            <input className='text-input'
            name={name}
            type={visiblePass ? 'text' : 'password'}
            placeholder='**********'
            style={{paddingLeft:18}}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={value}
            />
            <div style={{cursor:'pointer',height:50,width:50, justifyContent:'center', display:'flex', alignItems: 'center'}} onClick={() => setVisiblePass(visiblePass ? false : true)}>
            {!visiblePass ? <FiEye /> : <FiEyeOff />}
            </div>
        </div>
        {(errors && touched) &&
            <div style={{ marginTop:3}}>
            <p className='errorText'>{errors}</p>
            </div>
        }
    </>
  );
}

export default Password;