import logo from './logo.svg';
import { BrowserRouter, Routes, Route, HashRouter, Navigate } from "react-router-dom";
import Home from './pages/Home';
import Health from './pages/Health';
import Create from './pages/Create';
import Notifications from './pages/Notifications';
import Library from './pages/Library';
import Shop from './pages/Shop';
import PetOwnerProfile from './pages/PetOwnerProfile';
import Header from './components/Header';
import AddPet from './pages/Pet/AddPet';
import EditPet from './pages/Pet/EditPet';
// import './App.css';

function App() {
  return (
    <div className="App">
        <Header />
        <Routes>
          <Route index element={<Home />}/>
          <Route path="healthcard" element={<Health />} />
          <Route path="create" element={<Create />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="library" element={<Library />} />
          <Route path="shop" element={<Shop />} />
          <Route path="edit" element={<Navigate to="/" />} />
          <Route path="edit/:id" element={<PetOwnerProfile />} />
          <Route path="add-pet" element={<AddPet />} />
          <Route path="edit-pet" element={<EditPet />} />
        </Routes>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
