import React, { useRef, useEffect, useContext, useState } from 'react';
import { Outlet, Link } from "react-router-dom";
import Header from '../../components/Header';
// import './index.css';

function AddPet() {

  useEffect(() => {
    
  }, []);

  return (
    <>
      <div className="Main-Container-Outer"> 
        <div className="AddPetPage Main-Container"> 
            <h1>ADD PET</h1>
            <Outlet />
        </div>
      </div>
    </>
  );
}

export default AddPet;