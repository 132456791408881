import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles.css';
// import './index.css';
// import App from './App';
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { UserContextProvider } from './context/UserContext';
import AuthNavigator from './navigation/AuthNavigation';
import ScrollToTop from './components/ScrolltoTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <ScrollToTop />
      <UserContextProvider>
        <AuthNavigator />
      </UserContextProvider>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
