import React, {useState, useContext, useRef, useEffect} from "react";
import { Outlet, Link, NavLink } from "react-router-dom";
import { FiUser, FiSettings, FiLifeBuoy, FiLogOut, FiBriefcase } from "react-icons/fi";

import { UserContext } from '../context/UserContext';

function Header(props) {
  const {
    checked,
  } = props;

  const [notification, setNotification ] = useState(true)

  const { user, logoutUser } = useContext(UserContext);

  const iconsSize = 44;

  const [userDropDownVisible, setUserDropDownVisible] = useState(false)
  const OpenUserDropDown = () =>{
    setUserDropDownVisible(true)
  }
  const CloseUserDropDown = () =>{
    setUserDropDownVisible(false)
  }
  const ref = useRef();
  useOnClickOutside(ref, () => setUserDropDownVisible(false));



  return (
    <header className="Header-LoggedIn">
        <div className='Header-Centered'>
            <Link className='Logo-LoggedIn' to="/">
                <svg width="80" viewBox="0 0 218.6 86.6">
                    <path className="st0" fill="#7474A3" d="M0,1.7h16.7v66H0V1.7z"/>
                    <path className="st1" fill="#54B754" d="M160.8,33.2h-10.9v34.6h-16.7V33.2h-8l0-13.1h8.3v-4.6C133.4,5.7,138.7,0,149,0h11.7v12.8h-7.4
                    c-2.5,0-4,1.5-4,3.9v3.3h11.4V33.2z"/>
                    <path className="st2" fill="#FF6666" d="M218.6,20.1l-20.3,46.4c-6.5,14.8-12.6,20.2-25.1,20.2h-5.7V72.3h4.6c6.4,0,8.3-1.7,10.6-7.7l-20-44.5h18.2
                    l10.3,26.7l9.6-26.7H218.6z"/>
                    <circle className="st3" fill="#6E9EF5" cx="45.8" cy="43.5" r="25.8"/>
                    <circle className="st4" fill="#FFCC33" cx="99.3" cy="43.5" r="25.8"/>
                </svg>
            </Link>
            <nav className="Nav-LoggedIn">
                <NavLink to="/" title={notification ? "Αρχική (Υπάρχουν ειδοποιήσεις)" : "Αρχική"}>
                  <span>
                  <svg height={iconsSize} width={iconsSize} viewBox="0 0 500 500">
                            <path fill='#7474A3' d="M368.6,240.7l-16.8,126.1c-0.8,7.6-7.2,13.4-14.9,13.4H311c-8.3,0-15-6.7-15-15v-63.5
	c0-24.5-18.8-45.2-43.2-46.7c-26.5-1.6-48.8,19.7-48.8,45.9v64.3c0,8.3-6.7,15-15,15h-25.9c-7.6,0-14.1-5.8-14.9-13.4l-16.8-126.1
	c-0.5-4.5,1.1-8.9,4.3-12.1l103.7-104.3c5.8-5.9,15.4-5.9,21.2,0l103.7,104.3C367.5,231.7,369,236.2,368.6,240.7z"/>
                        </svg>
                        {notification ? <span className="Notification-Dot"></span> : null}
                        
                        </span>
                </NavLink>
                <NavLink to="/healthcard" title="Κάρτα υγείας">
                  <span>
                    <svg height={iconsSize} width={iconsSize} viewBox="0 0 500 500">
                            <path fill='#FF6666' d="M261.4,237.5l-23.2,78.9c-1,3.6-4.3,6-8,6.1c0,0-0.1,0-0.1,0c-3.7,0-6.9-2.4-8.1-5.8l-39.5-120.2L168.9,232
                              c-1.3,3.3-4.4,5.5-7.9,5.5h-41c3.6,13.5,9.7,27.1,18.3,40.6c20.2,32,53.7,63.4,99.7,93.3c3.6,2.4,7.8,3.6,12.1,3.6
                              s8.5-1.2,12.1-3.6c45.9-29.9,79.5-61.3,99.7-93.3c8.5-13.5,14.6-27.1,18.3-40.6H261.4z"/>
                            <path fill='#FF6666' d="M155.1,220.5l19.9-52.5c1.3-3.4,4.5-5.6,8.1-5.5c3.6,0.1,6.8,2.4,7.9,5.8l38.5,117.1l17.3-58.8
                              c1.1-3.6,4.4-6.1,8.2-6.1h128.3c1-8.3,0.9-16.5-0.2-24.5c-2.2-16.1-8.5-31-18.2-43.1c-9-11.2-20.7-19.5-33-23.4
                              c-9.1-2.9-18.1-4.4-26.6-4.4c-22.3,0-42,9.8-56.4,27.8c-16-18.3-34.8-27.5-56-27.5c-8,0-16.4,1.4-24.9,4.1
                              c-12.2,3.9-24,12.2-33,23.4c-9.8,12.1-16.1,27-18.2,43.1c-1.1,8-1.1,16.2-0.2,24.5H155.1z"/>
                    </svg>
                        </span>
                </NavLink>
                {/* <NavLink to="/notifications" title="Εκπαίδευση">
                    <svg height={iconsSize} width={iconsSize} viewBox="0 0 42 42">
                    <path fill='#f2bf29' d="M14.6,29.5C14.6,29.5,14.6,29.6,14.6,29.5c-0.8,1.7-0.1,3.6,1.6,4.4s3.6,0,4.4-1.6c0,0,0,0,0,0L14.6,29.5z"/>
			        <path fill='#f2bf29' d="M28.9,31.6l-0.9-5.2c0.6-1.2,1.9-4.2,2.3-4.9c1.5-3.3,0.4-7.2-2.4-9.3l0.7-1.5c0.3-0.7,0-1.6-0.7-2
				s-1.6,0-2,0.7l-0.7,1.5c-3.4-0.8-7.1,0.9-8.6,4.2c-0.6,1.3-1.9,4.2-2.3,4.9l-4.5,2.7c-0.5,0.3-0.8,0.8-0.7,1.4
				c0,0.6,0.4,1,0.9,1.3l16.9,7.8c0.5,0.2,1.1,0.2,1.5-0.2C28.8,32.7,29,32.1,28.9,31.6z"/>
                    </svg>
                </NavLink> */}
                <NavLink to="/library" title="Βιβλιοθήκη">
                  <span>
                    <svg height={iconsSize} width={iconsSize} viewBox="0 0 42 42">
                    <path fill='#6E9EF5' d="M14.8,13.6v-1c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5v10.2c0,0,0,0.1,0,0.1c0,0.8,0,1.7,0,2.5h-0.4
		c-0.8,0-1.4,0.6-1.4,1.3c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0.8,0.7,1.4,1.4,1.4h0.4c0,0.4,0,0.7,0,1h-0.4c0,0-0.1,0-0.1,0
		c-1.3-0.1-2.3-1.1-2.3-2.4c0,0,0-0.1,0-0.1c0-1.5,0-12.8,0-14c0,0,0,0,0-0.1c0-1.3,1.1-2.4,2.4-2.4h0.8h12.1h0.1
		c0.3,0,0.5,0.2,0.5,0.5v1.9h-9.8C16.3,12.6,15.4,12.9,14.8,13.6z M16,30.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0.8,0.7,1.4,1.4,1.4h12.6
		v-1.4c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v1.9c0,0.3-0.2,0.5-0.5,0.5H17.3c0,0-0.1,0-0.1,0C16,32.5,15,31.5,15,30.2
		c0,0,0-0.1,0-0.1c0-1.5,0-12.8,0-14c0,0,0,0,0-0.1c0-1.3,1.1-2.4,2.4-2.4h0.8h12.1h0.1c0.3,0,0.5,0.2,0.5,0.5v14.1
		c0,0.3-0.2,0.5-0.5,0.5c0,0,0,0,0,0c0,0-0.1,0-0.1,0H17.4C16.6,28.8,16,29.4,16,30.1z M17.7,26.2c0,0.3,0.2,0.5,0.5,0.5
		c0.3,0,0.5-0.2,0.5-0.5V16c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5V26.2z"/>
                    </svg>
                        </span>
                </NavLink>
                <NavLink to="/shop" title="Αγορές">
                  <span>
                    <svg height={iconsSize} width={iconsSize} viewBox="0 0 42 42">
                    <path fill='#54B754' d="M26.6,14.3c0,0,0-0.1,0-0.1c0-3.1-2.6-5.7-5.7-5.7s-5.7,2.6-5.7,5.7c0,0,0,0.1,0,0.1h-3.7v15.4
	c0,2,1.8,3.7,3.8,3.7h11.2c2,0,3.8-1.7,3.8-3.7V14.3H26.6z M20.9,10.6c2,0,3.6,1.6,3.6,3.6c0,0,0,0.1,0,0.1h-7.2c0,0,0-0.1,0-0.1
	C17.3,12.2,18.9,10.6,20.9,10.6z M24.6,21.8c-0.3,0.9-1.2,1.5-2,1.2c-0.8-0.3-1.2-1.2-0.9-2.2c0.3-0.9,1.2-1.5,2-1.2
	C24.5,19.9,24.9,20.8,24.6,21.8z M21.2,20.7c0,1-0.7,1.8-1.5,1.8s-1.5-0.8-1.5-1.8c0-1,0.7-1.8,1.5-1.8
	C20.6,18.9,21.2,19.7,21.2,20.7z M15.5,22.9c-0.3-0.8,0-1.6,0.6-1.8c0.6-0.3,1.4,0.2,1.7,0.9c0.3,0.8,0,1.6-0.6,1.8
	C16.6,24.1,15.8,23.7,15.5,22.9z M24.2,28.1c-0.5,0.5-1.2,0.7-1.9,0.4c-0.6-0.2-1.1-0.4-1.7-0.4c-0.6,0-1.2,0.1-1.7,0.4
	c-0.7,0.3-1.4,0.1-1.9-0.4c-0.5-0.5-0.5-1.3-0.1-1.9l1.4-2.2c0.5-0.8,1.4-1.3,2.3-1.3c0.9,0,1.8,0.5,2.3,1.3l1.4,2.2
	C24.8,26.8,24.7,27.6,24.2,28.1z M26.3,24.6c-0.5,0.7-1.3,0.8-1.9,0.4c-0.6-0.4-0.6-1.3-0.1-1.9c0.5-0.7,1.3-0.8,1.9-0.4
	C26.7,23.1,26.8,24,26.3,24.6z"/>
                    </svg>
                        </span>
                </NavLink>
            </nav>
            <div className="Create-UserAvatar-Container">
                    <NavLink className='button Nav-Create-LoggedIn' to="/create" title="Δημιουργία">
                        <svg height={iconsSize-8} width={iconsSize-8} viewBox="0 0 500 500">
                                <path fill='#7474A3' d="M232.1,232.1v35.8H126.9c-9.8,0-17.9-8.1-17.9-17.9c0-4.9,2-9.4,5.3-12.7c3.3-3.3,7.7-5.3,12.6-5.3H232.1z"/>
                                <path fill='#54B754' d="M391,250c0,4.9-2,9.4-5.3,12.6c-3.3,3.3-7.7,5.3-12.6,5.3H267.9v-35.8h105.2C382.9,232.1,391,240.1,391,250z"/>
                                <path fill='#6E9EF5' d="M267.9,126.9v105.2h-35.8V126.9c0-9.8,8.1-17.9,17.9-17.9c4.9,0,9.4,2,12.6,5.3
                                C265.9,117.5,267.9,122,267.9,126.9z"/>
                                <path fill='#FF6666' d="M267.9,267.9v105.2c0,9.9-8.1,17.9-17.9,17.9c-4.9,0-9.4-2-12.7-5.3c-3.3-3.3-5.3-7.7-5.3-12.7V267.9H267.9z"/>
                                <rect fill='#FFCC33' x="232.1" y="232.1" width="35.8" height="35.8"/>
                        </svg>
                        <span>Δημιουργία</span>
                    </NavLink>
                    <div className="User-Avatar-LoggedIn-Container">
                        <div className='button User-Avatar-LoggedIn' onClick={()=>OpenUserDropDown()}>
                          {user ? (
                            <img src={user.petOwnerImage ? user.petOwnerImage : require("../assets/defaultUser.png")} />
                          ):(
                            <img src={require("../assets/defaultUser.png")} />
                          )}
                            
                        </div>
                        {user ? (
                          <div className={userDropDownVisible ? "User-DropDown-Container Show" : "User-DropDown-Container"} ref={ref}>
                              <div className="User-DropDown">
                                  <Link className="User-DropDown-Btn" to={`/edit/${user.petOwnerFullName.replace(/\s/g, '').toLowerCase()}`} onClick={CloseUserDropDown}><FiUser /> Προφίλ</Link>
                                  <Link className="User-DropDown-Btn" to="/settings" onClick={CloseUserDropDown}><FiSettings /> Ρυθμίσεις</Link>
                                  <Link className="User-DropDown-Btn" to="/settings" onClick={CloseUserDropDown}><FiBriefcase /> Για&nbsp;επιχειρήσεις</Link>
                                  <Link className="User-DropDown-Btn" to="/settings" onClick={CloseUserDropDown}><FiLifeBuoy /> Βοήθεια</Link>
                                  <div className="User-DropDown-Btn" onClick={()=>logoutUser()}><FiLogOut /> Αποσύνδεση</div>
                              </div>
                          </div>
                        ):(
                          null
                        )}
                    </div>
            </div>
            <Outlet />
        </div>
    </header>
  );
}



function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      [ref, handler]
    );
  }

export default Header;