export default [
    {
        value:1,
        label:"Δεν γνωρίζω",
        en:"I don't know",
        img:require('../assets/sterilized/thinking_emoji.png')
    }, 
    {
        value:2,
        label:"Έχει στειρωθεί",
        en:"Sterilized",
        img:require('../assets/sterilized/thumbs-up.png')
    }, {
        value:3,
        label:"Δεν εχει στειρωθεί",
        en:"Not sterilized",
        img:require('../assets/sterilized/thumbs-down.png')
    }
];
