import React, {useState, useContext, useEffect} from 'react';
import AvatarEditor from 'react-avatar-editor';
import { FiCheck, FiX, FiRotateCcw, FiRotateCw } from "react-icons/fi"
import { BsFillCameraFill} from "react-icons/bs";
import imageCompression from 'browser-image-compression'

import Loader from './Loader';

import {UserContext} from '../context/UserContext';


function ImgPickerUpdate({
    user, 
    confirmedShownImage, 
    setConfirmedShownImage, 
    width, 
    height, 
    borderRadius, 
    showPlaceHolder = true, 
    setCoverProcessing = false, 
    isCover = false,
    isPet = false,
    PreImage = null}) {

    const { updateUser } = useContext(UserContext);

    const [selectedImg, setSelectedImage] = useState();
    const [zoomSelectedImg, setZoomSelectedImg] = useState(1);
    const [rotateSelectedImg, setRotateSelectedImg] = useState(0);
    const [loadingState, setLoadingState] = useState(false);

    
    const inputFileRef = React.useRef();
    const editorRef = React.useRef(null);



    // COMPRESS IMAGE
    async function ImageCompression(file) {
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 1500,
          useWebWorker: true,
          alwaysKeepResolution: true,
        }
        try {
            const compressedFile = await imageCompression(file, options);
            const compressedBase64 = await imageCompression.getDataUrlFromFile(compressedFile)
            setSelectedImage(compressedBase64); 
            setLoadingState(false);
        } catch (error) {
            console.log(error);
        }
    }




    const onFileChangeCapture = (e) => {
        if(e.target.files[0]){
            setConfirmedShownImage(null)
            setLoadingState(true);
            ImageCompression(e.target.files[0])
            if(isCover && !selectedImg){
                setCoverProcessing(true)
            }
        }
    };

    const handleSlider = (e) => {
        setZoomSelectedImg(e.target.value)
    }

    const handleRotateLeft = () => {
        setRotateSelectedImg(rotateSelectedImg - 45)
    }
    const handleRotateRight = () => {
        setRotateSelectedImg(rotateSelectedImg + 45)
    }


    
    const CancelImage = () => {
        setSelectedImage(null)
        inputFileRef.current.value = "";
        if(isCover){
            setCoverProcessing(false)
        }
    }
    const ConfirmImage = () => {
        const dataUrl = editorRef.current.getImage().toDataURL();
        setSelectedImage(null)
        setConfirmedShownImage(dataUrl);
        inputFileRef.current.value = "";
        if(isCover){
            setCoverProcessing(false)
        }
        updateUser( 
            localStorage.getItem("token"), 
            user.petOwnerFullName, 
            user.petOwnerEmail, 
            user.petOwnerGPSLocationLat, 
            user.petOwnerGPSLocationLng, 
            dataUrl, 
            user.petOwnerLocation, 
            user.petOwnerZipCode
        );
        // console.log( 
        //     localStorage.getItem("token"), 
        //     user.petOwnerFullName, 
        //     user.petOwnerEmail, 
        //     user.petOwnerGPSLocationLat, 
        //     user.petOwnerGPSLocationLng, 
        //     dataUrl, 
        //     user.petOwnerLocation, 
        //     user.petOwnerZipCode
        // );
    }

    useEffect(() => {
        if(isCover){
            if(selectedImg){
                setCoverProcessing(true)
            }else if(!selectedImg){
                setCoverProcessing(false)
            }
        }
    }, [selectedImg]);




  return (
    <>
        <div className='Image-Picker'>
            <input 
                accept='image/png, image/jpeg'
                type='file' 
                name='selectedImg'
                style={{display:'none'}} 
                ref={inputFileRef}
                onChangeCapture={onFileChangeCapture}/>
            {selectedImg && !confirmedShownImage ? (
                <div className='ImgAndControls'>
                    <AvatarEditor
                        ref={editorRef}
                        image={selectedImg}
                        width={width}
                        height={height}
                        border={0}
                        borderRadius={borderRadius}
                        backgroundColor='#ffffff'
                        scale={parseFloat(zoomSelectedImg)}
                        rotate={rotateSelectedImg}
                    />
                    <div className='Image-Controls-Container'>
                        <div className='Image-Controls'>
                            <span>Ζουμ</span>
                            <input 
                                type="range" 
                                min="1" 
                                max="10" 
                                step="0.1"
                                onChange={handleSlider}
                                value={zoomSelectedImg}
                            />
                        </div>
                        <div className='Image-Controls'>
                            {!isCover ? (
                                <>
                                <div onClick={handleRotateLeft}><FiRotateCcw /></div>
                                <div onClick={handleRotateRight}><FiRotateCw /></div>
                                </>
                            ):(
                                <>
                                <div style={{opacity:0,width:0,padding:0, margin:0, pointerEvents:'none'}}></div>
                                <div style={{opacity:0,width:0,padding:0, margin:0, pointerEvents:'none'}}></div>
                                </>
                            )}
                            
                            <div onClick={CancelImage}><FiX /></div>
                            <div onClick={ConfirmImage}><FiCheck style={{marginRight:5}}/> Έγινε!</div>
                        </div>
                    </div>
                
                </div>
            ):(
                <div className={confirmedShownImage ? 'ImgPlaceHolder done' : 'ImgPlaceHolder'} onClick={()=>inputFileRef.current.click()}>
                    {confirmedShownImage ? <img src={confirmedShownImage} /> : <img src={PreImage} />}
                    <div className='Hover-Camera-Profile-Img'>
                        <BsFillCameraFill />
                    </div>
                    {loadingState ? (
                        <div style={{backgroundColor: 'rgba(255,255,255,0.7)',position:'absolute',width:'100%',height:'100%', top:0,left:0, display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type='internal' />
                        </div>
                    ):null}
                </div>
            )
            }
        </div>
    </>
  );
}

export default ImgPickerUpdate;